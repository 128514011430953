import React, { useEffect } from 'react'
import { useAuth } from './AuthContext'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { getDatabase, ref, onDisconnect, set } from 'firebase/database';
import { getAuth } from 'firebase/auth'
import { useLocation } from 'react-router';

export default function UserOnline({ children }) {

    let location = useLocation();
    const { currentUser } = useAuth()

    const db = getFirestore()
    const database = getDatabase()
    var currentId = JSON.parse(sessionStorage.getItem("current_event"))

    async function loadData() {
        if (location.pathname === "/dashboard/live") {

            await updateDoc(doc(db, 'users', currentUser.user.uid), {
                "isOnline": true
            })
            set(ref(database, `users/${currentUser.user.uid}`), {
                status: "online",
                editingEvent: currentId,
                name: `${currentUser.data.firstname} ${currentUser.data.lastname}`
            });

            onDisconnect(ref(database, `users/${currentUser.user.uid}/status/`)).set("offline")
        } else {
            set(ref(database, `users/${currentUser.user.uid}`), {
                status: "offline",
                editingEvent: "",
                name: "",

            });
        }

    }

    if (currentUser) {
        loadData()
    }

    return (
        <>{children}</>
    )
}
