import React, { useEffect, useState } from 'react'
import SidePanel from '../components/SidePanel'
import Header from '../components/header/Header'
import boxIcon from '../images/box-icon.svg'
import Events from '../components/Events'
import PopupBox from '../components/Popup-box'
//import {firestore} from '../firebase'

export default function BoxScreen() {

    const [loading, setloading] = useState(false)
    const [popup, setPopup] = useState(false)
    const [boxes, setboxes] = useState([])
    const [displayInPopup, setdisplayInPopup] = useState({})
    //const db=firestore


    /*useEffect(() => {
        const boxesRef = db.collection('boxes')
        let data=[]
        boxesRef.orderBy('boxId','asc').onSnapshot((snapshot=>{
                setboxes(snapshot.docs)            
        }))       
        
    }, [])*/


    function openPopup(box) {
        setPopup(true)
        setdisplayInPopup(box)
    }

    function onClose() {
        setPopup(false)
    }

    return (
        <div className="row">

            <SidePanel id={4}></SidePanel>

            <div className="column content">
                <Header></Header> 
                <PopupBox popup={popup} close={()=>{onClose()}} box={displayInPopup}></PopupBox>
                
                <div className="box-content">
                    <div className="card box-table-wrap">
                    {/*boxes.map(box=>{
                        
                        return(
                            <div 
                                onClick={()=>{openPopup(box.data())}} 
                                className={box.data().isRented + " item box-shadow"}
                                
                            >

                                <span>{box.data().boxId}</span>
                                <img src={boxIcon} alt="box-icon" />
                            </div>
                        )

                    })*/}   

                    </div>
                    <Events></Events>
                    
                </div>
                
            
            <div className="another">SOMETHING</div>
                
                

            </div>
        </div>
    )
}
