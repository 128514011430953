import React, { useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { useAuth } from './contexts/AuthContext'
import { getFirestore, setDoc, addDoc, collection, updateDoc, doc } from "firebase/firestore"
import { useNavigate } from 'react-router'
import { ValidateEventDate, ValidateLocation, ValidateTitle } from './contexts/Validation'

export default function AddNewEventForm() {
    const [error, seterror] = useState("")
    const titleRef = useRef()
    const dateRef = useRef()
    const locationRef = useRef()
    const { currentUser } = useAuth()
    const navigate = useNavigate()


    const [titleError, settitleError] = useState("")
    const [dateError, setdateError] = useState("")
    const [locationError, setlocationError] = useState("")

    async function HandleSubmit(e) {
        e.preventDefault()
        settitleError("")
        setdateError("")
        setlocationError("")

        if (!titleRef.current.value) {
            settitleError("Názov je povinné vyplniť")
            return
        }

        if (!locationRef.current.value) {
            setlocationError("Miesto konania je povinné vyplniť")
            return
        }

        if (!dateRef.current.value) {
            setdateError("Dátum konania je povinné vyplniť")
            return
        }

        const vTitle = ValidateTitle(titleRef.current.value)
        const vDate = ValidateEventDate(dateRef.current.value)
        const vLocation = ValidateLocation(locationRef.current.value)

        if (vTitle || vDate || vLocation) {
            settitleError(vTitle)
            setdateError(vDate)
            setlocationError(vLocation)
            return
        }



        const date = Date.parse(dateRef.current.value)
        const db = getFirestore()

        const addedEvent = await addDoc(collection(db, "events_grid"), {
            "status": "created",
            "date": date,
            "title": titleRef.current.value,
            "circuit_location": locationRef.current.value,
            "details": {
                "description": "",
                "image": "https://firebasestorage.googleapis.com/v0/b/bikes-planet.appspot.com/o/default-img.png?alt=media&token=008a90e2-ad93-4ead-962c-7fc619e69a96",
            },

            "price": 0,
            "difficulty": 0,
            "organizer": {
                "id": currentUser.data.userId,
                "name": currentUser.data.firstname + " " + currentUser.data.lastname,
                "avatar": currentUser.data.avatar ? currentUser.data.avatar : "",
            },
            "team": [currentUser.data.userId]
        })


        //addDoc(collection(db, `/event_grid/${snap.id}/live`))
        const stats = await setDoc(doc(db, `/events_grid/${addedEvent.id}/stats`, 'stats'), {
            "totalRegistrations": 0,
            "arePresent": 0,
            "groups": {
                "A": 0,
                "B": 0,
                "C": 0,
                "D": 0,
            }
        })

        await setDoc(doc(db, `/events_grid/${addedEvent.id}/live`, 'live'), {
            "editing_events": []
        })

        await setDoc(doc(db, `/events_grid/${addedEvent.id}/results`, 'results'), {
            "results": [],
            status: "created"
        })

        const newCurrentEvent = {
            "date": date,
            "id": addedEvent.id,
            "title": titleRef.current.value,
            "status": "created"
        }

        currentUser.data.events.push(newCurrentEvent)
        const events = currentUser.data.events

        await updateDoc(doc(db, "users", currentUser.data.userId), {
            "events": events
        })

        sessionStorage.setItem("current_event", JSON.stringify(newCurrentEvent.id))
        navigate("/dashboard/settings")
    }

    return (

        <div className="add-new-event">
            <div className="title">Pridať nový pretek</div>

            <div className='input-wrap'>
                <input className="box-shadow form-input" ref={titleRef} placeholder="Názov" type="text" id="title" />
                <span className='form-validation-error'>{titleError && titleError}</span>
            </div>

            <div className='input-wrap'>
                <input className="box-shadow form-input" ref={locationRef} placeholder="Miesto konania" type="text" id="location" />
                <span className='form-validation-error'>{locationError && locationError}</span>
            </div>

            <div className='input-wrap'>
                <input className="box-shadow form-input" ref={dateRef} placeholder="Dátum konania" type="date" id="date" />
                <span className='form-validation-error'>{dateError}</span>
            </div>


            <motion.button
                className="button-primary button-login "
                onClick={HandleSubmit}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.95 }}
            >
                Vytvoriť event
            </motion.button>
        </div>

    )
}
