import React, { useRef } from 'react'
import closeIcon from '../../images/remove.svg'

export default function HarmonogramItem({ disabled, item, update, i, error, removeItem }) {

    const titleRef = useRef()
    const startRef = useRef()
    const endtRef = useRef()

    function parseTime(time) {
        if (isNaN(time)) {

            var split = time.split(':')
            var min = parseInt(split[1])
            var hod = parseInt(split[0])
            const time_in_sec = hod * 60 + min
            return time_in_sec

        } else {

            var min = time % 60
            var hod = Math.floor(time / 60);

            return `${hod < 10 ? "0" : ""}${hod}:${min < 10 ? "0" : ""}${min}`
        }
    }


    function onChange(e) {
        update({
            title: titleRef.current.value,
            start: parseTime(startRef.current.value),
            end: parseTime(endtRef.current.value),
        }, i)
    }

    return (
        <>

            <div className={`${error ? 'error-row' : ''} harmonogram-item-wrap box-shadow`}>
                <div className="id"><b>{i + 1}</b></div>
                <input type="text" className='title' disabled={disabled} ref={titleRef} onChange={onChange} defaultValue={item.title} placeholder='Zadajte názov...' />
                <div className="start-end">
                    <input className='time' disabled={disabled} id='time-start' type="time" ref={startRef} defaultValue={parseTime(item.start)} onChange={onChange} />
                    <input className='time' disabled={disabled} id='time-end' type="time" ref={endtRef} defaultValue={parseTime(item.end)} onChange={onChange} />
                </div>
                {!disabled && <div onClick={() => { removeItem(i) }}>
                    <svg className='remove-icon' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 2C10.4477 2 10 2.44772 10 3V4H14V3C14 2.44772 13.5523 2 13 2H11ZM16 4V3C16 1.34315 14.6569 0 13 0H11C9.34315 0 8 1.34315 8 3V4H3C2.44772 4 2 4.44772 2 5C2 5.55228 2.44772 6 3 6H3.10496L4.80843 21.3313C4.97725 22.8506 6.26144 24 7.79009 24H16.2099C17.7386 24 19.0228 22.8506 19.1916 21.3313L20.895 6H21C21.5523 6 22 5.55228 22 5C22 4.44772 21.5523 4 21 4H16ZM18.8827 6H5.11726L6.7962 21.1104C6.85247 21.6169 7.28054 22 7.79009 22H16.2099C16.7195 22 17.1475 21.6169 17.2038 21.1104L18.8827 6ZM10 9C10.5523 9 11 9.44771 11 10V18C11 18.5523 10.5523 19 10 19C9.44772 19 9 18.5523 9 18V10C9 9.44771 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44771 15 10V18C15 18.5523 14.5523 19 14 19C13.4477 19 13 18.5523 13 18V10C13 9.44771 13.4477 9 14 9Z" />
                    </svg>
                </div>}

            </div>
        </>

    )
}
