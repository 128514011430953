import React from 'react';
import { Route, Routes } from 'react-router';
import LogIn from '../components/LogIn';
import { useAuth } from '../contexts/AuthContext';
import AddEventScreen from '../screens/AddEventScreen';
import BoxScreen from '../screens/BoxScreen';
import CheckoutScreen from '../screens/CheckoutScreen';
import DashboardScreen from '../screens/DashboardScreen';
import JazdciScreen from '../screens/JazdciScreen';
import LiveScreen from '../screens/LiveScreen';
import ManualRegistration from '../screens/ManualRegistration';
import NotAdminScreen from '../screens/NotAdminScreen';
import RegroupingScreen from '../screens/Regrouping/RegroupingScreen';
import SelectEventInitial from '../screens/SelectEventInitial';
import SettingsScreen from '../screens/SettingsScreen';
import StatistikyScreen from '../screens/StatistikyScreen';
import TestPdf from '../screens/TestPdf';
import ResultsScreen from '../screens/ResultsScreen'

export default function CustomRoute() {
    const { currentUser } = useAuth()
    // pouźívatel nieje prihlásený

    if (!currentUser) {
        return (
            <LogIn />
        )
    }
    //pouzivatel nieje admin
    if (currentUser.data.role !== "admin") {
        return (
            <>
                <Routes>
                    <Route path="/*" element={<NotAdminScreen />}></Route>
                    <Route exact path="/checkout" element={<CheckoutScreen />} />
                </Routes>
            </>
        )

    }

    //pouzivatel nema zvoleny event
    if (currentUser.data.role === "admin") {

        const current_event = JSON.parse(sessionStorage.getItem("current_event"))
        const events = Object.values(currentUser.data.events)
        let event_ids = []

        events.forEach((item) => {
            event_ids.push(item.id)
        })

        if (!sessionStorage.getItem("current_event") || !event_ids.includes(current_event)) {
            return (
                <Routes>
                    <Route path="/dashboard/add-new" element={<AddEventScreen />} />
                    {<Route path="/*" element={<SelectEventInitial />} />}
                </Routes>

            )
        }
        //je v zozname eventov
        return (
            <Routes>
                <Route path="/" element={<DashboardScreen />} exact></Route>
                <Route path="/dashboard" element={<DashboardScreen />} exact></Route>
                <Route path="/dashboard/add-new" element={<AddEventScreen />} />
                <Route path="/dashboard/add-registration" element={<ManualRegistration />} />
                <Route path="/dashboard/jazdci" element={<JazdciScreen />} ></Route>
                <Route path="/dashboard/statistiky" element={<StatistikyScreen />} ></Route>
                <Route path="/dashboard/boxy" element={<BoxScreen />} ></Route>
                <Route path="/dashboard/settings" element={<SettingsScreen />} ></Route>
                <Route path="/dashboard/live" element={<LiveScreen />} ></Route>
                <Route path="/dashboard/regrouping" element={<RegroupingScreen />} ></Route>
                <Route path="/dashboard/results" element={<ResultsScreen />} ></Route>
            </Routes>
        )


    }
    return <div>Chyba</div>
}

