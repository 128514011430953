import React, { useEffect, useRef, useState } from 'react'
import LoadingButton from '../components/LoadingButton'
import { getFirestore, doc, collection, addDoc } from 'firebase/firestore'
import errorIcon from '../images/error_icon-red.svg'
import { useAuth } from '../contexts/AuthContext'


export default function SendNotification() {

    const [loading, setloading] = useState(false)
    const [success, setsuccess] = useState("")
    const [pastNotifications, setpastNotifications] = useState([])
    const [error, seterror] = useState("")
    const [sendTo, setsendTo] = useState()
    const [sendToAll, setsendToAll] = useState(true)

    const { currentUser } = useAuth()

    const messageRef = useRef()

    const db = getFirestore()
    const current_event = JSON.parse(sessionStorage.getItem("current_event"))

    function SendNotification() {
        seterror("")
        setloading(true)
        const message = messageRef.current.value
        if (!message || message === "") {
            seterror("Správa nemôže byť prázdna")
            setloading(false)
            return
        }

        const notificationsRef = collection(db, `events_grid/${current_event}/notifications`)
        addDoc(notificationsRef, {
            "message": message,
            date: new Date().getTime(),
            sender: {
                userId: currentUser.data.userId,
                userName: `${currentUser.data.firstname} ${currentUser.data.lastname}`
            }
        }).then(() => {

            setsuccess("Správa bola úspešne odoslaná")
            document.querySelector('textarea#message').value = ""
            setTimeout(() => {
                setsuccess("")
            }, 2000);
            setloading(false)
            return

        }).catch((err) => {
            seterror(JSON.stringify(err))
            setloading(false)
            return
        })
    }


    return (
        <div className='main-content'>
            <div>
                <h1>Notifikácie</h1>
                <div className='notification-wrap'>
                    {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                    {success && <div className="success"><div className="success-message">{success}</div></div>}
                    <textarea ref={messageRef} name="message" id="message" cols="15" rows="7" className='form-input' placeholder='Vaša správa'>
                    </textarea>
                    <div className="sendTo">
                        <label htmlFor="toAll">
                            <input type="checkbox" name="toAll" id="toAll" checked={sendToAll} onClick={() => { setsendToAll(!sendToAll) }} />
                            Odoslať všetkým
                        </label>
                    </div>

                    <LoadingButton onClick={SendNotification} text="Odoslať" textLoading="Odosielam" loading={loading}></LoadingButton>
                </div>
            </div>
        </div>
    )
}
