import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/header/Header'
import SidePanel from '../components/SidePanel'
import { collection, getFirestore, getDocs, doc, getDoc, addDoc, query, orderBy, updateDoc, where, setDoc } from 'firebase/firestore'
import LoadingButton from '../components/LoadingButton'
import { AnimatePresence, motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'


export default function ResultsScreen() {

    const [eventData, seteventData] = useState()
    const [eventDataLoading, seteventDataLoading] = useState(true)
    const [searchLocation, setsearchLocation] = useState({ top: 0, left: 0 })
    const [displaySearch, setdisplaySearch] = useState(false)
    const [searchResult, setsearchResult] = useState()
    const [displayedInCategory, setdisplayedInCategory] = useState()
    const [loadingSearch, setloadingSearch] = useState(false)
    const [noResults, setnoResults] = useState(false)
    const [data, setdata] = useState({})
    const [saving, setsaving] = useState(false)
    const [publishing, setpublishing] = useState(false)

    const navigate = useNavigate()

    const db = getFirestore()
    const currentEventId = JSON.parse(sessionStorage.getItem("current_event"))

    var typingTimer;
    var doneTypingInterval = 1000;

    useEffect(() => {
        const eventData = doc(db, `/events_grid/${currentEventId}/`)
        getDoc(eventData).then((eventSnap) => {
            var data = eventSnap.data()
            seteventData({ "eventId": eventSnap.id, ...data })
            seteventDataLoading(false)
            console.log({ "eventId": eventSnap.id, ...data })
        })

    }, [])

    function HandleDisplaySearch(e, location) {
        var position = e.target.getBoundingClientRect()
        setdisplaySearch(false)
        setsearchResult([])
        setdisplayedInCategory(location)
        setnoResults(false)

        setTimeout(() => {
            setsearchLocation({
                top: position.top + 35,
                left: position.left,
            })
            setdisplaySearch(true)
        }, 300);
    }



    function onSearch(key) {

        if (!key || key === "") {
            return
        } else {
            setloadingSearch(true)
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                const q = query(collection(db, `events_grid/${currentEventId}/registrations`), where("startNumber", '==', key))
                getDocs(q).then((snap) => {
                    var poi = []
                    if (!snap.empty) {
                        snap.docs.filter(item => (
                            poi.push(item.data())
                        ))

                        setsearchResult(poi)
                        setloadingSearch(false)
                        setnoResults(false)
                    } else {
                        setloadingSearch(false)
                        setnoResults(true)
                    }
                }).catch((err) => {
                })

            }, doneTypingInterval);
        }

    }

    function SelectRider(rider) {

        var selectedCategory = displayedInCategory.cat
        var selectedPlace = displayedInCategory.place

        var newData = data

        var existsCategory = (`${selectedCategory}` in data)
        var existsPlace = false

        if (existsCategory) {
            existsPlace = (`place${selectedPlace}` in data[`${selectedCategory}`])
        }



        if (existsCategory) {
            if (existsPlace) {
                newData[selectedCategory]["place" + selectedPlace] = {

                    riderId: rider.rider.riderId,
                    riderName: rider.rider.name,
                    riderNumber: rider.startNumber,
                }
            }
            else {
                newData[selectedCategory][`place${selectedPlace}`] = {
                    riderId: rider.rider.riderId,
                    riderName: rider.rider.name,
                    riderNumber: rider.startNumber,
                }

            }
        } else {
            newData[selectedCategory] = {
                [`place${selectedPlace}`]: {
                    riderId: rider.rider.riderId,
                    riderName: rider.rider.name,
                    riderNumber: rider.startNumber,
                }
            }
        }
        setdata(newData)
        setdisplaySearch(false)

    }

    var icons = [
        <svg width="30" height="30" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.00493C16.6337 2.00493 20.8015 6.17275 20.8015 11.3064C20.8015 16.4401 16.6337 20.6079 11.5 20.6079C6.36632 20.6079 2.19851 16.4401 2.19851 11.3064C2.19851 6.17275 6.36632 2.00493 11.5 2.00493ZM11.5 3.22393C7.0391 3.22393 3.41751 6.84553 3.41751 11.3064C3.41751 15.7673 7.0391 19.3889 11.5 19.3889C15.9609 19.3889 19.5825 15.7673 19.5825 11.3064C19.5825 6.84553 15.9609 3.22393 11.5 3.22393ZM11.5 4.54893C15.2296 4.54893 18.2575 7.57682 18.2575 11.3064C18.2575 15.036 15.2296 18.0639 11.5 18.0639C7.7704 18.0639 4.74251 15.036 4.74251 11.3064C4.74251 7.57682 7.7704 4.54893 11.5 4.54893ZM11.1548 6.71303L11.1528 6.71419L9.77475 7.56219C9.46343 7.75384 9.36612 8.16226 9.55777 8.47369C9.74942 8.78501 10.1578 8.88232 10.4693 8.69067L10.8375 8.46404V14.1419H9.85701C9.49141 14.1419 9.19451 14.4388 9.19451 14.8044C9.19451 15.17 9.49141 15.4669 9.85701 15.4669H13.143C13.5086 15.4669 13.8055 15.17 13.8055 14.8044C13.8055 14.4388 13.5086 14.1419 13.143 14.1419H12.1625V7.27843C12.1625 6.91284 11.8656 6.61593 11.5 6.61593C11.3735 6.61593 11.2553 6.65144 11.1548 6.71303Z" fill="url(#paint0_linear_218_130)" />
            <defs>
                <linearGradient id="paint0_linear_218_130" x1="-0.500001" y1="18.5" x2="22" y2="6.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFC700" />
                    <stop offset="1" stopColor="#FFE452" />
                </linearGradient>
            </defs>
        </svg>,

        <svg width="30" height="30" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.00493C16.6337 2.00493 20.8015 6.17275 20.8015 11.3064C20.8015 16.4401 16.6337 20.6079 11.5 20.6079C6.36632 20.6079 2.19851 16.4401 2.19851 11.3064C2.19851 6.17275 6.36632 2.00493 11.5 2.00493ZM11.5 3.22393C7.0391 3.22393 3.41751 6.84553 3.41751 11.3064C3.41751 15.7673 7.0391 19.3889 11.5 19.3889C15.9609 19.3889 19.5825 15.7673 19.5825 11.3064C19.5825 6.84553 15.9609 3.22393 11.5 3.22393ZM11.5 4.54893C15.2296 4.54893 18.2575 7.57682 18.2575 11.3064C18.2575 15.036 15.2296 18.0639 11.5 18.0639C7.7704 18.0639 4.74251 15.036 4.74251 11.3064C4.74251 7.57682 7.7704 4.54893 11.5 4.54893ZM9.48601 8.25893H11.924C12.4363 8.25893 12.8515 8.67413 12.8515 9.18643V9.51312C12.8515 9.86727 12.6122 10.1767 12.2694 10.2656C11.5663 10.448 10.6677 10.6811 10.048 10.8419C9.32701 11.029 8.82351 11.6798 8.82351 12.4247C8.82351 13.4108 8.82351 15.0164 8.82351 15.0164C8.82351 15.3823 9.1201 15.6789 9.48601 15.6789H13.514C13.8796 15.6789 14.1765 15.382 14.1765 15.0164C14.1765 14.6508 13.8796 14.3539 13.514 14.3539H10.1485V12.4247C10.1485 12.2834 10.244 12.1599 10.3808 12.1245L12.6021 11.5482C13.5292 11.3076 14.1765 10.4709 14.1765 9.51312C14.1765 9.40638 14.1765 9.29667 14.1765 9.18643C14.1765 7.94241 13.168 6.93393 11.924 6.93393H9.48601C9.12041 6.93393 8.82351 7.23084 8.82351 7.59643C8.82351 7.96202 9.12041 8.25893 9.48601 8.25893Z" fill="url(#paint0_linear_218_127)" />
            <defs>
                <linearGradient id="paint0_linear_218_127" x1="-32" y1="-37.5" x2="45.5" y2="18.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#70706F" />
                    <stop offset="1" stopColor="#BEC0C2" />
                </linearGradient>
            </defs>
        </svg>,

        <svg width="30" height="30" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.0385C16.6337 2.0385 20.8015 6.20632 20.8015 11.34C20.8015 16.4737 16.6337 20.6415 11.5 20.6415C6.36632 20.6415 2.19851 16.4737 2.19851 11.34C2.19851 6.20632 6.36632 2.0385 11.5 2.0385ZM11.5 3.2575C7.0391 3.2575 3.41751 6.8791 3.41751 11.34C3.41751 15.8009 7.0391 19.4225 11.5 19.4225C15.9609 19.4225 19.5825 15.8009 19.5825 11.34C19.5825 6.8791 15.9609 3.2575 11.5 3.2575ZM11.5 4.5825C15.2296 4.5825 18.2575 7.61039 18.2575 11.34C18.2575 15.0696 15.2296 18.0975 11.5 18.0975C7.7704 18.0975 4.74251 15.0696 4.74251 11.34C4.74251 7.61039 7.7704 4.5825 11.5 4.5825ZM10.2201 11.34C10.222 11.3413 10.224 11.3425 10.226 11.3437L13.09 13.0072V14.4013H9.24751C8.88191 14.4013 8.58501 14.6982 8.58501 15.0638C8.58501 15.4295 8.88191 15.7263 9.24751 15.7263H13.7525C14.1184 15.7263 14.415 15.4297 14.415 15.0638V12.6258C14.415 12.3897 14.2894 12.1715 14.0852 12.053L12.3049 11.0188L13.9126 10.6187C14.2078 10.5452 14.415 10.2801 14.415 9.97578V7.53778C14.415 7.17187 14.1184 6.87528 13.7525 6.87528H9.24751C8.88191 6.87528 8.58501 7.17219 8.58501 7.53778C8.58501 7.90348 8.88191 8.20028 9.24751 8.20028H13.09V9.45797L10.3987 10.1279C10.0439 10.2163 9.82754 10.5761 9.91584 10.9308C9.96025 11.1093 10.0734 11.2528 10.2201 11.34Z" fill="url(#paint0_linear_218_124)" />
            <defs>
                <linearGradient id="paint0_linear_218_124" x1="-16" y1="-40" x2="42" y2="18.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CD7F32" />
                    <stop offset="1" stopColor="#FFAC5A" />
                </linearGradient>
            </defs>
        </svg>
    ]

    useEffect(() => {

        const regroupingRef = doc(db, `/events_grid/${currentEventId}/results/results/`)
        getDoc(regroupingRef).then((snap) => {
            if (snap.exists()) {
                var x = snap.data().results
                setdata({ ...x })
                console.log({ ...x })
            } else {
            }
        }).catch(err => {
        })

    }, [])

    function HandleSave(status = "created") {
        const regroupingRef = doc(db, `/events_grid/${currentEventId}/results/results`)

        if (status === "created") {
            setsaving(true)
        }
        if (status === "published") {
            setpublishing(true)
        }


        setDoc(regroupingRef, {
            status: status,
            results: { ...data }
        }).then(xnap => {
            console.log({ ...data })
            updateDoc(doc(db, `events_grid/${eventData.eventId}`), {
                results: { ...data },
                publishedResults: true,
            }).then().catch(err => {
                console.log(err)
            })

            if (status === "created") {
                setsaving(false)
            }

            if (status === "published") {
                setpublishing(false)
            }
        })
    }

    function removeResult(place, category) {
        let newData = { ...data }
        delete newData[category][`place${place}`]

        setdata(newData)
    }

    return (
        <div className="row dashboard results">
            <SidePanel id={5}></SidePanel>
            <div className="column content">
                <AnimatePresence>
                    {displaySearch &&
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="search" style={{ left: searchLocation.left, top: searchLocation.top }}>

                            {!noResults && searchResult.length === 0 && "Zadajte štartovné číslo"}
                            {noResults && "Jazdec sa nenašiel..."}
                            <div className="search-results">
                                {!noResults && searchResult.length > 0 && searchResult.map((item, i) => {
                                    return (
                                        <div key={i} className="table-row card" onClick={() => { SelectRider(item) }}>
                                            <div>{item.startNumber}</div>
                                            <div>{item.rider.name}</div>
                                        </div>
                                    )
                                })
                                }
                            </div>

                        </motion.div>
                    }
                </AnimatePresence>

                <Header></Header>
                <div className="">
                    <div className="main-content-wraper">
                        <div className='card'>
                            <h1>Výsledky</h1>
                            <p>Tu môžete uverejniť výsledky preteku v jednotlivých kategóriach</p>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="main-content-wraper">
                        <div className='scroll-wrap card'>
                            <div className='scroll'>
                                {!eventDataLoading && eventData.category ? <div className="categories-wrap">
                                    {
                                        eventData.category.map((item, i) => {
                                            return (
                                                <div key={i} className="category card">
                                                    <h1>{item.fullName}</h1>
                                                    {icons.map((icon, j) => {
                                                        var selected
                                                        var existsCategory = (`${item.shortenName}` in data)
                                                        var existsPlace = false

                                                        if (existsCategory) {
                                                            existsPlace = (`place${j + 1}` in data[`${item.shortenName}`])
                                                        }

                                                        if (existsCategory && existsPlace) {
                                                            selected = data[`${item.shortenName}`][`place${j + 1}`]
                                                        }

                                                        return (
                                                            <div className="places">
                                                                <div className={`place card ${selected ? 'selected' : ""}`} >
                                                                    {icon}
                                                                    {!selected ? <input placeholder='Zadajte číslo ...' type="text" className='form-input' onFocus={(e) => { HandleDisplaySearch(e, { cat: item.shortenName, place: j + 1 }) }} onChange={(e) => { onSearch(e.target.value) }} />
                                                                        : <>
                                                                            <div>{selected.riderNumber} - {selected.riderName}</div>
                                                                            <svg onClick={() => { removeResult(j + 1, item.shortenName) }} className='remove' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M14 8C14 5.79 12.21 4 10 4C7.79 4 6 5.79 6 8C6 10.21 7.79 12 10 12C12.21 12 14 10.21 14 8ZM17 10V12H23V10H17ZM2 18V20H18V18C18 15.34 12.67 14 10 14C7.33 14 2 15.34 2 18Z" />
                                                                            </svg>
                                                                        </>

                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })
                                    }
                                </div> :
                                    <>
                                        <div>Tento pretek nemá pridelené žiadne kategórie</div>
                                        <button className='button-primary' onClick={() => { navigate('dashboard/settings') }}>Nastavenia</button>
                                    </>
                                }

                            </div>
                            {!eventDataLoading && eventData.category && <div className="buttons-wrap">
                                <LoadingButton text="Uložiť" textLoading="Ukladám" onClick={() => { HandleSave() }} loading={saving} />
                                <LoadingButton text="Publikovať výsledky" textLoading="Publikujem výsledky" onClick={() => { HandleSave("published") }} className="publish" loading={publishing} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

