import React from 'react'
import SidePanel from '../components/SidePanel'

export default function StatistikyScreen() {
    return (
        <div className="row">
            <SidePanel id={3}></SidePanel>
            <div>
                <h1>StatistikyScreen</h1>
            </div>
        </div>
    )
}
