import React, { useContext, useEffect, useState } from 'react'

const themeContext = React.createContext()

export function ThemeContext({ children }) {

    const [currentTheme, setcurrentTheme] = useState('')

    useEffect(() => {
        var theme = window.localStorage.getItem('theme')
        if (theme) setcurrentTheme(theme)
        if (!theme) setTheme('light')
        document.querySelector('body').setAttribute('theme', theme)
    }, [])

    function setTheme(theme) {
        setcurrentTheme(theme)
        window.localStorage.setItem('theme', theme)
        document.querySelector('body').setAttribute('theme', theme)
    }



    const value = {
        currentTheme,
        setTheme
    }

    return (
        <themeContext.Provider value={value}>
            <div id='theme' theme={currentTheme}>{children}</div>
        </themeContext.Provider>
    )
}

export function useTheme() {
    return useContext(themeContext)
}
