const data = {


    timeHarmonogram: [
        {
            "id": 1,
            "name": "Kategória D",
            "start": new Date().setHours(9, 40, 0),
            "end": new Date().setHours(9, 50, 0)

        },
        {
            "id": 2,
            "name": "Kategória C",
            "start": new Date().setHours(9, 50, 0),
            "end": new Date().setHours(10, 0, 0)

        },

        {
            "id": 3,
            "name": "Kategória C",
            "start": new Date().setHours(10, 0, 0),
            "end": new Date().setHours(10, 30, 0)

        },

        {
            "id": 4,
            "name": "Kategória C",
            "start": new Date().setHours(10, 30, 0),
            "end": new Date().setHours(11, 0, 0)

        },

        {
            "id": 5,
            "name": "Kategória C",
            "start": new Date().setHours(11, 0, 0),
            "end": new Date().setHours(11, 17, 0)

        },

        {
            "id": 6,
            "name": "Kategória C",
            "start": new Date().setHours(11, 17, 0),
            "end": new Date().setHours(11, 30, 0)

        },

        {
            "id": 7,
            "name": "Kategória C",
            "start": new Date().setHours(11, 30, 0),
            "end": new Date().setHours(12, 0, 0)

        },

        {
            "id": 8,
            "name": "Kategória C",
            "start": new Date().setHours(12, 0, 0),
            "end": new Date().setHours(13, 0, 0)

        },
        {
            "id": 9,
            "name": "Kategória C",
            "start": new Date().setHours(13, 0, 0),
            "end": new Date().setHours(14, 0, 0)

        },
        {
            "id": 10,
            "name": "Kategória C",
            "start": new Date().setHours(14, 0, 0),
            "end": new Date().setHours(15, 0, 0)

        },
        {
            "id": 11,
            "name": "Kategória C",
            "start": new Date().setHours(15, 0, 0),
            "end": new Date().setHours(16, 0, 0)

        },
        {
            "id": 12,
            "name": "Kategória C",
            "start": new Date().setHours(16, 0, 0),
            "end": new Date().setHours(17, 0, 0)

        },
        {
            "id": 13,
            "name": "Kategória C",
            "start": new Date().setHours(19, 0, 0),
            "end": new Date().setHours(19, 28, 0)

        },
        {
            "id": 13,
            "name": "Kategória D",
            "start": new Date().setHours(21, 0, 0),
            "end": new Date().setHours(22, 0, 0)
        },
        {
            "id": 13,
            "name": "Kategória A",
            "start": new Date().setHours(22, 0, 0),
            "end": new Date().setHours(22, 30, 0)
        },
        {
            "id": 13,
            "name": "Kategória B",
            "start": new Date().setHours(22, 30, 0),
            "end": new Date().setHours(23, 0, 0)
        },






    ]
};

export default data;