import React, { useEffect, useState } from 'react'
import data from '../data'
import { getClockState } from './Clock'

export default function TimeHarmonogramTable() {
    const [displayEvents, setdisplayEvents] = useState([]);
    const [loading, setloading] = useState(false);


    function stringToDate(date) {
        let x = new Date(date)
        x = x.toLocaleTimeString()
        return x.substring(0, x.length - 3)
    }

    useEffect(() => {
        //BUG pri nacitavani prvy krat -- nenacital data, po refreshi ok

        setloading(true);
        const poi = data.timeHarmonogram.filter((timeEvent) => timeEvent.end >= getClockState());
        if (poi.length > 3) {
            poi.splice((poi.length - 3) * -1)
        }
        setdisplayEvents(poi);
        setloading(false);

        data.timeHarmonogram[0].start.toString()

        setInterval(() => {

            setloading(true);

            const poi = data.timeHarmonogram.filter((timeEvent) => timeEvent.end >= getClockState());

            if (poi.length > 3) {
                poi.splice((poi.length - 3) * -1)
            }


            setdisplayEvents(poi)
            setloading(false);

        }, 5000);
    }, [])


    return (
        loading ? "" :
            displayEvents.map((timeEvent, i) => (


                <div className={i === 0 ? "active-time box-shadow card time-harmonogram-row" : "box-shadow card time-harmonogram-row"}>

                    <div className="time ">{`${stringToDate(timeEvent.start)} - ${stringToDate(timeEvent.end)}`}</div>
                    <div className="group">{timeEvent.name}</div>
                </div>



            ))


    )
}
