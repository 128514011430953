import React, { useRef, useState } from 'react'
import Header from '../components/header/Header'
import SidePanel from '../components/SidePanel'
import { useAuth } from '../contexts/AuthContext'
import { useToast } from '../contexts/ToastContext'
import { query, getDocs, doc, orderBy, startAt, endAt, collection, getFirestore, getDoc, addDoc, updateDoc, limit } from 'firebase/firestore'
import { AnimatePresence, motion } from 'framer-motion'
import errorIcon from '../images/error_icon-red.svg'

export default function ManualRegistration() {

    const [edited, setedited] = useState(false)
    const [category, setcategory] = useState()
    const [haveForm, sethaveForm] = useState()
    const [searchResult, setsearchResult] = useState([])
    const [displaySearch, setdisplaySearch] = useState(false)
    const [displayMotorcycle, setdisplayMotorcycle] = useState(false)
    const [selectedUser, setselectedUser] = useState(null)
    const [selectedMotorcycle, setselectedMotorcycle] = useState(null)
    const [searchError, setsearchError] = useState()
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState()
    const db = getFirestore()


    const { currentUser } = useAuth()

    const startNumberRef = useRef()
    const lapTimeRef = useRef()
    const notesRef = useRef()



    function HandleSelect(item) {

        setselectedUser(item)
        setdisplaySearch(false)
        setsearchResult([])
        setdisplayMotorcycle(true)
    }

    function HandleSelectMotorcycle(item) {
        console.log(item)
        setselectedMotorcycle(item)
        setdisplayMotorcycle(false)
    }


    function HandleSearch(e) {
        const db = getFirestore()
        const poi = e.target.value
        const collectionRef = collection(db, 'users')

        if (poi.length >= 3) {
            const q = query(collectionRef, orderBy('email', 'asc'), startAt(poi), endAt(`${poi}\uf8ff`), limit(3))
            getDocs(q).then(snap => {
                if (!snap.empty) {
                    setsearchError('')
                    var x = []
                    snap.forEach(item => {
                        x.push(item.data())
                    })
                    setsearchResult(x)
                    setdisplaySearch(true)
                } else {
                    setdisplaySearch(true)
                    setsearchResult([])
                    setsearchError("Používateľ sa nenašiel")
                }
            })

        } else {
            setsearchResult([])
            setdisplaySearch(false)
        }
    }

    async function SubmitData() {


        if (selectedUser === null) {
            seterror("Vyberte používateľa")
            return
        }

        if (selectedMotorcycle === null) {
            seterror("Vyberte motorku")
            return
        }

        const registered_for = selectedUser.registered_for
        const startNumber = startNumberRef.current.value
        const lapTime = lapTimeRef.current.value
        const notes = notesRef.current.value
        const currentEventId = JSON.parse(sessionStorage.getItem("current_event"))

        if (registered_for.includes(currentEventId)) {
            seterror("Používateľ je na pretek už registrovaný")
            return
        }

        const time = lapTime.split(':')
        var min = time[0]
        var sec = time[1]

        min = parseInt(min)
        sec = parseInt(sec)

        seterror("")

        if (lapTime) {

            const time = lapTime.split(':')
            if (time.length < 1) {
                seterror("Čas zadaný v zlom formáte (chýba \":\")")
                return
            } else {
                const min = time[0]
                const sec = time[1]
                if (isNaN(min) || isNaN(sec)) {
                    seterror("Čas zadaný v zlom formáte")
                    return
                }

                if (min < 0 || min > 60 || sec < 0 || sec > 60) {
                    seterror("Čas zadaný v zlom formáte")
                    return
                }
            }
        }

        const ref = doc(db, `events_grid`, currentEventId)
        const eventSnapshot = await getDoc(ref)

        const eventData = eventSnapshot.data()

        const registration = {
            rider: {
                riderId: selectedUser.userId,
                email: selectedUser.email,
                name: selectedUser.firstname + " " + selectedUser.lastname,
                phoneNumber: selectedUser.tel,
            },
            eventId: currentEventId,
            category: category ? category : "",
            motorcycle: {
                cubature: selectedMotorcycle.cubature,
                model: selectedMotorcycle.model,
            },
            haveForm: false,
            isPresent: false,
            notes: notes,
            oneLapTime: min * 60 + sec + 0,
            paymentMethod: "hotovost",
            paymentReceived: false,
            registrationDate: new Date().getTime() * 1000,
            startNumber: startNumber || "",
            priceForRegistration: eventData.price,
            status: "active"
        }

        const docRef = collection(db, `/events_grid/${currentEventId}/registrations/`)
        const userRef = doc(db, `/users/${selectedUser.userId}/`)

        setloading(true)

        addDoc(docRef, registration).then((snap) => {

            if (selectedUser.registered_for) {
                selectedUser.registered_for.push(currentEventId)
            }
            else {
                selectedUser.registered_for = [currentEventId]
            }
            console.log(selectedUser.registered_for)
            updateDoc(userRef, { "registered_for": selectedUser.registered_for })

            setloading(false)
            document.location.reload()
            return

        }).catch((err) => {
            seterror(err)
            setloading(false)
        })
    }

    return (
        <div div className="manual-registration" >
            {console.log(searchResult)}
            <div className="row">
                <SidePanel></SidePanel>
                <div className='content'>
                    <Header></Header>
                    <div className='register-wrap'>
                        <div className='select-user'>
                            {!selectedUser ? <div className='find-rider'>
                                <label htmlFor="pretekar">Pretekár</label>
                                <input className="form-input" type="text" id="pretekar" placeholder='Hľadať...' onChange={HandleSearch} />

                                <AnimatePresence>
                                    {displaySearch &&

                                        <motion.div
                                            initial={{ opacity: 0, y: -100 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            className="select-item-dropdown"

                                        >
                                            <div className="wrap">
                                                {searchError && searchError}
                                                {searchResult.map((item, i) => {

                                                    return (
                                                        <div key={i + 1} onClick={() => { HandleSelect(item) }} className={`event-item-wrap`}>
                                                            <div className="event-item">
                                                                <div className="event-name">{item.firstname} {item.lastname}</div>
                                                                <span className="event-id">{item.email}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </motion.div>
                                    }

                                </AnimatePresence>
                            </div>
                                :
                                <div className='selected-rider-wrap'>
                                    <img alt="person" src={`${selectedUser.avatar ? selectedUser.avatar : "https://firebasestorage.googleapis.com/v0/b/bikes-planet.appspot.com/o/avatar%2Fdefault_icon.svg?alt=media&token=d0ac3d3c-b196-4eca-a320-4897ff7dc3f8"}`} />
                                    <div>
                                        <div><b>Pretekár</b></div>
                                        <div>{selectedUser.firstname} {selectedUser.lastname} - {selectedUser.email}</div>
                                    </div>

                                </div>
                            }

                            <AnimatePresence>
                                {displayMotorcycle &&
                                    <div className='motorcycle'>
                                        <motion.div
                                            initial={{ opacity: 0, y: -100 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            className="select-item-dropdown"

                                        >
                                            <div className="wrap">
                                                {selectedUser.motorcycles.map((item, i) => {

                                                    return (
                                                        <div key={i + 1} onClick={() => { HandleSelectMotorcycle(item) }} className={`event-item-wrap`}>
                                                            <div className="event-item">
                                                                <div className="event-name"><b>{item.model}</b></div>
                                                                <span className="event-id"> - {item.cubature} cm3</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </motion.div>
                                    </div>
                                }
                                {selectedMotorcycle && <div>
                                    <b>Motorka</b>
                                    <div>{selectedMotorcycle.model} - {selectedMotorcycle.cubature} cm3</div>
                                </div>}

                            </AnimatePresence>
                        </div>
                    </div>
                    {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                    <div className="actions-content">
                        <div><label htmlFor="startNumber">Číslo</label> <input className="form-input" ref={startNumberRef} type="number" id="startNumber" /></div>
                        <div><label htmlFor="category">Kategória</label>
                            <select name="category" onChange={(e) => { setcategory(e.target.value) }} id="cat-select" className='form-input' >
                                <option value="">Vyberte...</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="oneLapTime">Čas j. kola</label>
                            <input className="form-input" ref={lapTimeRef} type="text" id="oneLapTime" />
                        </div>

                    </div>

                    <div className="popup-columns-wrap">
                        <div className="popup-note-column">

                            <label htmlFor="input-notes">Poznámky</label>
                            <textarea cols="15" rows="8" id="notes" ref={notesRef} placeholder="Poznámky" ></textarea>

                        </div>
                        <div className="popup-present-column" >
                        </div>
                    </div>
                    <motion.button
                        className='button-primary'
                        onClick={SubmitData}
                    >
                        Odoslať
                    </motion.button>
                </div>
            </div>
        </div>

    )
}
