import React, { useCallback, useContext, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Toast } from '../components/Toast'

const toastCon = React.createContext()


export function ToastProvider(props) {

    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity: "0"
        },
        visible: {
            y: "0",
            opacity: "1",
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 300,
            }
        },
        exit: {
            opacity: 0,
        },
    }

    const [showToast, setShowToast] = useState(false)
    const [message, setmessage] = useState(null)
    const [type, settype] = useState(null)

    const displayToast = useCallback(
        function (message, type) {
            setShowToast(true)
            setmessage(message)
            settype(type)

            setTimeout(() => {
                hideToast()
            }, 2000);
        }


    )

    function hideToast() {
        setShowToast(false)
    }

    return (

        <toastCon.Provider value={displayToast}>
            <AnimatePresence exitBeforeEnter>
                {showToast &&
                    <motion.div
                        key={"toast"}
                        className="toast"
                        variants={dropIn}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <Toast type={type} message={message} close={hideToast}></Toast>
                    </motion.div>
                }
            </AnimatePresence>
            {props.children}
        </toastCon.Provider>

    )
}

export function useToast() {
    return useContext(toastCon);
}
