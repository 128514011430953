import { motion } from 'framer-motion';
import React, { useContext, useRef, useState } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions"
import Team from '../Team';

const TeamContext = React.createContext()

export default function TeamPopup({ children }) {

    const reasonRef = useRef();
    const [error, seterror] = useState("");
    const [loading, setloading] = useState(false);
    const [teamPopup, setteamPopup] = useState(false)

    const animation = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.2
            }
        },

        show: {
            opacity: 1,
            transition: {
                duration: 0.2
            }
        }
    }

    function TeamPopup() {

        seterror("")
        const reason = reasonRef.current.value

        if (!reason) {
            seterror("Musíte uviesť dôvod diskvalifikácie")
            return
        }

        const functions = getFunctions()
        const setRiderStatus = httpsCallable(functions, "setRiderStatus")
        setloading(true)
    }

    function openTeam() {
        setteamPopup(true)
    }
    function closeTeam() {
        setteamPopup(false)
    }

    const value = {
        openTeam,
        closeTeam
    }

    return (

        <TeamContext.Provider value={value}>
            {children}
            {teamPopup && <motion.div
                className='team-popup'
                variants={animation}
                initial={"hidden"}
                animate={"show"}
                exit={"hidden"}
            >
                <div className='wrap'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <h3>Členovia teamu</h3>
                        <div className="close" onClick={() => { closeTeam() }} >
                            <svg xmlns="http://www.w3.org/2000/svg" className='close-icon' viewBox="0 0 48 48">
                                <g id="cancel" transform="translate(0 -0.001)">
                                    <g id="Group_104" data-name="Group 104" transform="translate(0 0.001)">
                                        <path id="Path_28" data-name="Path 28" d="M26.652,24l20.8-20.8A1.875,1.875,0,0,0,44.8.55L24,21.349,3.2.55A1.875,1.875,0,0,0,.549,3.2L21.348,24,.549,44.8A1.875,1.875,0,0,0,3.2,47.452L24,26.653l20.8,20.8A1.875,1.875,0,1,0,47.451,44.8Z" transform="translate(0 -0.001)" fill="#fff" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <Team></Team>

                </div>
            </motion.div>}
        </TeamContext.Provider>

    )
}

export function useTeamPopup() {
    return useContext(TeamContext)
}

