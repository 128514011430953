import React, { useEffect, useState } from 'react'
import Header from '../components/header/Header'
import PopupRider from '../components/popups/Popup-rider'
import SidePanel from '../components/SidePanel'
import { collection, getFirestore, onSnapshot, doc, getDoc } from 'firebase/firestore'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import useWindowDimensions from '../hooks/useWindowDimensions'
import Loading from '../components/Loading'


export default function JazdciScreen() {

    const [riders, setriders] = useState([])
    const [popup, setpopup] = useState(false)
    const [displayInPopup, setdisplayInPopup] = useState()
    const [filterCategory, setfilterCategory] = useState("")
    const [filterPresent, setfilterPresent] = useState("")
    const [filterCubature, setfilterCubature] = useState("")
    const [eventData, seteventData] = useState()
    const [editable, seteditable] = useState(true);
    const [loading, setloading] = useState(true)
    const [search, setsearch] = useState("")


    const currentEventId = JSON.parse(sessionStorage.getItem("current_event"))
    const { height, width } = useWindowDimensions()

    useEffect(() => {
        const db = getFirestore()
        const eventRegistrationsRef = collection(db, `/events_grid/${currentEventId}/registrations/`)
        const eventData = doc(db, `/events_grid/${currentEventId}/`)

        getDoc(eventData).then((x) => {
            var data = x.data()
            seteventData(data)
            if (data.status !== "published") {
                seteditable(false)
            }
            setloading(false)
        })

        const unsub = onSnapshot(eventRegistrationsRef, (doc) => {
            var poi = []

            doc.forEach((item) => {
                poi.push({ registrationId: item.id, ...item.data() })

            })
            setriders(poi)

        });

    }, [])



    function resetFilter() {
        document.getElementById("category-filter").value = "";
        document.getElementById("present-filter").value = "";
        setfilterCategory("")
        setfilterPresent("")

    }

    function filterChange(event) {
        switch (event.target.id) {
            case "category-filter": {
                setfilterCategory(event.target.value)
                break;
            }

            case "cubature-filter": {
                setfilterCubature(event.target.value)
                break;
            }

            case "present-filter": {
                if (event.target.value === "true") setfilterPresent(true)
                else if (event.target.value === "false") setfilterPresent(false)
                else setfilterPresent("")
                break;
            }
            default: {
                break
            }
        }
    }

    function ConvertToTime(sec) {
        if (isNaN(sec) || sec === 0)
            return "0:00"
        else if (sec !== 0)
            return `${Math.trunc(sec / 60)}:${sec % 60 < 10 ? "0" : ""}${sec % 60}`
    }


    return (
        <div className="row riders-screen">

            <AnimatePresence exitBeforeEnter>
                {popup && <PopupRider eventData={eventData} ConvertToTime={ConvertToTime} close={() => { setpopup(false) }} rider={displayInPopup} motion={motion}></PopupRider>}
            </AnimatePresence>

            <SidePanel id={3}></SidePanel>
            <div key="123" className="column content heading-jazdci">

                <Header></Header>

                {!loading ? <div className="table-filter">
                    <select className="select-filter" name="category-filter" id="category-filter" onChange={filterChange} >
                        <option value="">Kategória...</option>

                        {eventData.category && eventData.category.map(item => (
                            <option value={item.shortenName}>{item.fullName}</option>
                        ))}
                    </select>

                    <select className="select-filter" name="select-filter" id="present-filter" onChange={filterChange} >
                        <option value="">Prítomnosť...</option>
                        <option value="true">Prítomný</option>
                        <option value="false">Neprítomný</option>
                    </select>

                    <div className='search'>
                        <input className='form-input' type="text" name="search" id="s" placeholder='Hľadať...' autoComplete='off'
                            onChange={(e) => { setsearch(e.target.value) }}
                        />
                    </div>
                </div> : <Loading></Loading>}
                <div className='main-content-wraper'>
                    <div className="main-content card table-wrap">
                        <div>
                            {!loading ? <div className={`table-content ${!editable ? "cancelled" : ""}`}>

                                <div className="box-shadow active-time card table-row">
                                    <div className="align-center"><b>Č.</b></div>
                                    <div><b>Meno</b></div>
                                    <div><b>Kategória</b></div>
                                    <div><b>Čas j. kola</b></div>
                                    <div><b>Kubatúra</b></div>
                                    <div><b>Status</b></div>
                                </div>

                                {riders.length === 0 ? <div>Zatiaľ žiadne registrácie</div> :
                                    riders.map((rider, key) => {
                                        if (rider.status === "disqualify") { rider.startNumber = "DSQ" }
                                        if (rider.status === "cancelled") { rider.startNumber = "CAN" }
                                        let display = true;

                                        if (filterCategory !== rider.category && filterCategory !== "") display = false;
                                        if (filterPresent !== rider.isPresent && filterPresent !== "") display = false;
                                        var name = rider.rider.name

                                        if (search != "" && !name.startsWith(search)) display = false


                                        if (display === true) {
                                            return (
                                                <div key={key} onClick={() => { setpopup(true); setdisplayInPopup(rider) }}>
                                                    <div className={`box-shadow card table-row ${rider.status !== "active" ? rider.status : ""}`}>
                                                        <div div className="align-center">{rider.startNumber}</div>
                                                        <div>{rider.rider.name}</div>
                                                        <div>{rider.category}</div>
                                                        <div>{ConvertToTime(rider.oneLapTime)}</div>
                                                        <div>{rider.motorcycle.cubature} cm3</div>

                                                        <div className='status-icons'>
                                                            <div className={rider.paymentReceived && "is-true"}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 10.18 18">
                                                                <path id="payment-received" d="M14.96,12.4c-2.27-.59-3-1.2-3-2.15,0-1.09,1.01-1.85,2.7-1.85,1.78,0,2.44.85,2.5,2.1h2.21a3.986,3.986,0,0,0-3.21-3.81V4.5h-3V6.66c-1.94.42-3.5,1.68-3.5,3.61,0,2.31,1.91,3.46,4.7,4.13,2.5.6,3,1.48,3,2.41,0,.69-.49,1.79-2.7,1.79-2.06,0-2.87-.92-2.98-2.1H9.48c.12,2.19,1.76,3.42,3.68,3.83V22.5h3V20.35c1.95-.37,3.5-1.5,3.5-3.55C19.66,13.96,17.23,12.99,14.96,12.4Z" transform="translate(-9.48 -4.5)" fill="#D9D9D9" />
                                                            </svg>
                                                            </div>

                                                            <div className={rider.haveForm && "is-true"}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.385" height="20" viewBox="0 0 15.385 20">
                                                                    <path id="have-form" d="M15.981,3.375H8.673A1.923,1.923,0,0,0,6.75,5.3V21.452a1.923,1.923,0,0,0,1.923,1.923H20.212a1.923,1.923,0,0,0,1.923-1.923V9.529ZM15.212,10.3V4.913L20.6,10.3Z" transform="translate(-6.75 -3.375)" fill="#ececec" />
                                                                </svg>
                                                            </div>

                                                            <div className={rider.isPresent && "is-true"}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                    <path id="is-present" d="M13.5,13.5A4.5,4.5,0,1,0,9,9,4.513,4.513,0,0,0,13.5,13.5Zm0,2.25c-2.981,0-9,1.519-9,4.5V22.5h18V20.25C22.5,17.269,16.481,15.75,13.5,15.75Z" transform="translate(-4.5 -4.5)" fill="#ececec" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div> : <Loading />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

