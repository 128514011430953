import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/header/Header'
import SidePanel from '../../components/SidePanel'
import { collection, getFirestore, getDocs, doc, getDoc, addDoc, query, orderBy, updateDoc } from 'firebase/firestore'
import errorIcon from '../../images/error_icon-red.svg'
import LoadingButton from '../../components/LoadingButton'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { motion } from 'framer-motion'


export default function RegroupingScreen() {

    const [riders, setriders] = useState()
    const [eventData, seteventData] = useState()
    const [editable, seteditable] = useState(true);
    const [loading, setloading] = useState(true)
    const [loadingRegistrations, setloadingRegistrations] = useState(true)
    const [loadingGroups, setloadingGroups] = useState(true)
    const [addNew, setaddNew] = useState(false)
    const [error, seterror] = useState()
    const [search, setsearch] = useState("")
    const [selectedInstance, setselectedInstance] = useState()
    const [instances, setinstances] = useState()
    const [changes, setchanges] = useState([])
    const [saving, setsaving] = useState(false)
    const [publishing, setpublishing] = useState(false)

    const groupingNameRef = useRef()
    const db = getFirestore()
    const currentEventId = JSON.parse(sessionStorage.getItem("current_event"))

    async function loadData() {

        const regroupingRef = collection(db, `/events_grid/${currentEventId}/regrouping`)

        const regroupingQuery = query(regroupingRef, orderBy("created", 'asc'))
        const regroupingData = await getDocs(regroupingQuery)

        if (!regroupingData.empty) {
            var poi2 = []
            regroupingData.forEach((item) => {
                poi2.push({ regroupingId: item.id, ...item.data() })
            })
            setinstances(poi2)

            //setselectedInstance(poi2[0])
            //setchanges(poi2[0].changes)
            setloadingGroups(false)
        }
    }


    useEffect(() => {

        const eventRegistrationsRef = collection(db, `/events_grid/${currentEventId}/registrations/`)
        const eventData = doc(db, `/events_grid/${currentEventId}/`)

        getDoc(eventData).then((eventSnap) => {
            var data = eventSnap.data()
            seteventData(data)
            if (data.status !== "published") {
                seteditable(false)
                setloading(false)
            }
        })


        getDocs(eventRegistrationsRef).then((registrationsSnap) => {
            var poi = []

            registrationsSnap.forEach((item) => {
                poi.push({ registrationId: item.id, ...item.data() })
            })
            setriders(poi)
            setloadingRegistrations(false)
        })


        loadData()

    }, [])


    function ConvertToTime(sec) {
        if (isNaN(sec) || sec === 0)
            return "0:00"
        else if (sec !== 0)
            return `${Math.trunc(sec / 60)}:${sec % 60 < 10 ? "0" : ""}${sec % 60}`
    }

    function addNewRegrouping() {
        seterror("")
        const regroupingName = groupingNameRef.current.value
        if (!regroupingName) {
            seterror("Zadajte názov")
            return
        }

        const eventRegistrationsRef = collection(db, `/events_grid/${currentEventId}/regrouping/`)

        addDoc(eventRegistrationsRef, {
            name: regroupingName,
            created: new Date().getTime(),
            changes: []
        }).then(item => {
            loadData()
            setaddNew(false)
        })

    }

    function HandleSelectInstance(item) {
        setselectedInstance(item)
        setchanges(item.changes)
    }

    function onCategoryChange(rider) {
        var change = {
            from: rider.category,
            to: rider.to,
            status: "created",
            rider: {
                riderId: rider.rider.riderId,
                riderName: rider.rider.name,
                registrationId: rider.registrationId
            }
        }

        if (change.from === change.to || change.to === "") {

            setchanges(changes.filter((item, i) => {
                if (item.rider.riderId !== change.rider.riderId) {
                    return item
                }
            }))

            return
        }

        var riderLocated = false
        var indexLocated = 0

        changes.map((item, i) => {
            if (item.rider.riderId === change.rider.riderId) {
                riderLocated = true
                indexLocated = i
            }
        })

        if (!riderLocated) {
            setchanges([...changes, change])
            return

        }

        if (riderLocated) {
            setchanges(changes.map((item, i) => {
                if (i === indexLocated) {
                    item.to = change.to
                }
                return item
            }))
        }
    }

    function HandleSave() {



        if (changes.length > 0) {

            return new Promise((resolve, reject) => {
                setsaving(true)
                const dataRef = doc(db, `/events_grid/${currentEventId}/regrouping/${selectedInstance.regroupingId}`)

                updateDoc(dataRef, {
                    changes: changes
                }).then(() => {
                    loadData()
                    setsaving(false)
                    resolve()

                }).catch(err => {
                    reject(err)
                    setsaving(false)
                })
            })


        } else {
            setsaving(false)
            return
        }

    }

    async function HandlePublish() {

        if (changes.length > 0) {
            const functions = getFunctions()
            const publishRegrouping = httpsCallable(functions, "publishRegrouping")
            await HandleSave()
            setpublishing(true)
            publishRegrouping({
                regroupingId: selectedInstance.regroupingId,
                eventId: currentEventId,
            }).then((res) => {
                window.location.reload()
            }).catch(err => {
                setpublishing(false)
            })
        }

    }
    return (
        <div className="row dashboard regrouping">
            <SidePanel id={4}></SidePanel>
            <div className="column content">
                <Header></Header>
                <div className="col">
                    <div className="main-content-wraper">
                        <div className='card'>
                            <h1>Regrouping</h1>
                            <div className="select-group-instance">
                                {!loadingGroups && instances.map((item, i) => {
                                    return (
                                        <div key={i}
                                            className={`group-instance ${selectedInstance && selectedInstance.name === item.name ? 'selected' : ""}`}
                                            onClick={() => { HandleSelectInstance(item) }}
                                        >
                                            <div>{item.name}</div>
                                        </div>
                                    )
                                })
                                }
                                <div className="group-instance add-new" >
                                    <span onClick={() => { setaddNew(!addNew) }}>Pridať</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className='add-new-icon' viewBox="0 0 22 22">
                                        <g id="plus_icon" transform="translate(-392.5 68.5)">
                                            <line id="Line_8" data-name="Line 8" x2="20" transform="translate(393.5 -57.5)" fill="none" stroke="#d33" strokeLinecap="round" strokeWidth="2" />
                                            <line id="Line_9" data-name="Line 9" y1="20" transform="translate(403.5 -67.5)" fill="none" stroke="#d33" strokeLinecap="round" strokeWidth="2" />
                                        </g>
                                    </svg>

                                    {addNew && <div className="add-new-regrouping">
                                        <div>Pridt nový regrouping</div>
                                        {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                                        <input type="text" className='form-input' ref={groupingNameRef} />
                                        <button className="button-primary" onClick={addNewRegrouping}>Pridať</button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedInstance && <div className="main-content-wraper">
                        {selectedInstance.status !== "published" ? <div className="card">
                            <div>Akcie</div>
                            <div className="buttons-wrap">
                                <LoadingButton text="Uložiť" textLoading="Ukladám" onClick={HandleSave} loading={saving} />
                                <LoadingButton text="Publikovať zmeny" textLoading="Publikujem zmeny" onClick={HandlePublish} loading={publishing} className="publish" />
                            </div>
                        </div> :
                            <div className="card">
                                <h1>Inštancia bola publikovaná</h1>
                            </div>
                        }
                    </div>}
                </div>

                <div className="col">
                    <div className="main-content-wraper">
                        <div className="table-wrap card">
                            {selectedInstance ? <>
                                {selectedInstance.status !== "published" ? <>
                                    {!loadingRegistrations &&
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            className={`table-content`}>
                                            <div className="box-shadow active-time card table-row">
                                                <div className="align-center"><b>Č.</b></div>
                                                <div><b>Meno</b></div>
                                                <div><b>Čas j. kola</b></div>
                                                <div><b>Kategória</b></div>
                                            </div>
                                            {riders ?
                                                riders.map((rider, key) => {
                                                    if (rider.category !== "") {
                                                        var value
                                                        changes.map((item) => {
                                                            if (item.rider.riderId === rider.rider.riderId) {
                                                                value = item.to
                                                            }
                                                        })

                                                        return (
                                                            <div key={key} >
                                                                <div className={`box-shadow card table-row ${rider.status !== "active" ? rider.status : ""}`}>
                                                                    <div div className="align-center">{rider.startNumber}</div>
                                                                    <div>{rider.rider.name}</div>

                                                                    <div>{ConvertToTime(rider.oneLapTime)}</div>
                                                                    <div className='change-group'>
                                                                        <div>{rider.category}</div>

                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z" fill="#323232" />
                                                                        </svg>

                                                                        <select className="select-filter" name="category-filter" id="category-filter" value={`${value ? value : ""}`} onChange={(e) => { onCategoryChange({ ...rider, 'to': e.target.value }) }}>
                                                                            <option value="">Kat...</option>
                                                                            {eventData.category && eventData.category.map(item => {
                                                                                return (<option value={item.shortenName}>{item.shortenName}</option>)
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                :
                                                <div>Zatiaľ žiadne dáta</div>
                                            }
                                        </motion.div>}
                                </> :
                                    <>
                                        <h1>Žiadne dáta</h1>
                                        <p>Táto inštancia bola publikovaná</p>
                                    </>
                                }
                            </> :
                                (
                                    <>
                                        <h1>Nieje zvolený žiaden regrouping</h1>
                                        <p>Vytvorte nový alebo vyberte z už vytvorených</p>
                                    </>
                                )}
                        </div>
                    </div>

                    <div className="main-content-wraper changes">

                        <div className="table-wrap card">
                            <h1>Vykonané zmeny</h1>
                            {changes && changes.map((item, i) => {
                                return (
                                    <div key={i} className="box-shadow card table-row ">
                                        <div>{item.from}</div>
                                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z" fill="#323232" />
                                        </svg>
                                        <div>{item.to}</div>
                                        <div>{item.rider.riderName}</div>
                                    </div>
                                )
                            })}

                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

