import React, { useEffect, useState } from 'react'
import Header from '../components/header/Header'
import SidePanel from '../components/SidePanel'
import { collection, getFirestore, onSnapshot, doc, getDoc, where, query, updateDoc, deleteField, orderBy, arrayUnion, arrayRemove } from 'firebase/firestore'
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion'
import { motion } from 'framer-motion'
import PopupRiderLive from '../components/popups/PopupRiderLive'
import { useAuth } from '../contexts/AuthContext'
import UserOnline from '../contexts/UserOnline'
import Loading from '../components/Loading'

const fadeIn = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.2
        }
    },

    visible: {
        opacity: 1,
        transition: {
            duration: 0.2
        }
    }
}

const tableRowAnimation = {

    new: {
        opacity: [0, 1],
        backgroundColor: ["#4bb543f2", "#ffffff"],
        transition: {
            duration: 0.4
        }
    },
    remove: {
        backgroundColor: ["#ffffff", "#dd3333"],
        opacity: 0,
        transition: {
            duration: 0.4
        }

    }

}

export default function LiveScreen() {

    const [riders, setriders] = useState([])
    const [popup, setpopup] = useState(false)
    const [displayInPopup, setdisplayInPopup] = useState()
    const [filterCategory, setfilterCategory] = useState("")
    const [filterPresent, setfilterPresent] = useState("")
    const [filterCubature, setfilterCubature] = useState("")
    const [eventData, seteventData] = useState()
    const [editable, seteditable] = useState(true);
    const [loading, setloading] = useState(true);
    const [loadingLive, setloadingLive] = useState(true);
    const [liveRegistrations, setliveRegistrations] = useState();



    const currentEventId = JSON.parse(sessionStorage.getItem("current_event"))
    const db = getFirestore()
    const { currentUser } = useAuth()

    async function LoadRegistrations(poi) {
        const eventRegistrationsRef = collection(db, `/events_grid/${currentEventId}/registrations/`)

        var registrationsData = []

        for (var i = 0; i < poi.length; i++) {
            const data = await getDoc(doc(db, `/events_grid/${currentEventId}/registrations/${poi[i]}`))
            registrationsData.push(data.data())
        }


        setriders(registrationsData)
        return "OK"

    }

    useEffect(() => {

        async function init() {
            const eventDataRef = doc(db, `/events_grid/${currentEventId}/`)
            const liveDataRef = collection(db, `/events_grid/${currentEventId}/registrations/`)
            const q = query(liveDataRef, where("isPresent", '==', true), where('live.status', '==', 'new'), orderBy("scan.time", 'desc'))

            getDoc(eventDataRef).then((x) => {
                var data = x.data()
                seteventData(data)
                setloading(false)
            })

            const unsub = onSnapshot(q, (doc) => {
                var data = []
                if (!doc.empty) {
                    doc.docs.forEach((item, i) => {
                        var x = item.data()
                        x = { registrationId: item.id, ...x }
                        data.push(x)
                    })
                    setriders(data)
                } else {
                    setriders([])
                }
            });

            const unsub2 = onSnapshot(doc(db, `/events_grid/${currentEventId}/live/live`), (doc) => {
                if (doc.exists) {
                    setliveRegistrations(doc.data().editing_events)
                    setloadingLive(false)
                }
            })
        }

        init()
    }, [])


    function ConvertToTime(sec) {
        if (isNaN(sec) || sec === 0)
            return "0:00"
        else if (sec !== 0)
            return `${Math.trunc(sec / 60)}:${sec % 60 < 10 ? "0" : ""}${sec % 60}`
    }

    async function openPopup(rider) {

        var currently_editing = false

        liveRegistrations.map(item => {
            if (item.registrationId === rider.registrationId) {
                currently_editing = true
            }
        })

        if (currently_editing) {
            return
        } else {

            setdisplayInPopup(rider)
            setpopup(true)
            /*const data = updateDoc(doc(db, `/users/${currentEventId}/registrations/${rider.registrationId}`), {
                "live": {
                    status: "editing",
                    editingBy: currentUser.data.firstname
                }
            })*/
            const data = updateDoc(doc(db, `/events_grid/${currentEventId}/live/live/`), {
                "editing_events":
                    arrayUnion(
                        {
                            editingBy: `${currentUser.data.firstname} ${currentUser.data.lastname}`,
                            registrationId: rider.registrationId,
                            userId: currentUser.data.userId
                        }
                    )
            })
            setdisplayInPopup(rider)
        }
    }

    function closePopup() {
        setpopup(false)
        const remove = {
            editingBy: `${currentUser.data.firstname} ${currentUser.data.lastname}`,
            registrationId: displayInPopup.registrationId,
            userId: currentUser.data.userId
        }

        const data = updateDoc(doc(db, `/events_grid/${currentEventId}/live/live`), {
            "editing_events":
                arrayRemove(remove)
        })
    }

    return (
        <UserOnline>
            <div className={`row riders-screen live-screen`}>
                <AnimatePresence exitBeforeEnter>
                    {popup && <PopupRiderLive eventData={eventData} ConvertToTime={ConvertToTime} close={() => { closePopup() }} rider={displayInPopup} motion={motion}></PopupRiderLive>}
                </AnimatePresence>

                <SidePanel id={2}></SidePanel>
                <div key="123" className="column content heading-jazdci">
                    <Header></Header>
                    <div></div>

                    {!loading ? <div className='main-content-wraper'>
                        {eventData.status === "published" ? <div className="main-content card table-wrap">

                            <div className={`table-content ${!editable ? "cancelled" : ""}`}>
                                <div className="box-shadow active-time card table-row">
                                    <div><b>Čas scanu</b></div>
                                    <div><b>Meno</b></div>
                                    <div><b>Čas</b></div>
                                    <div><b>Kubatúra</b></div>
                                    <div><b>Status</b></div>
                                </div>

                                <AnimatePresence>
                                    {riders.length === 0 ? <div>Zatiaľ žiadne dáta</div> : liveRegistrations &&
                                        riders.map((rider, key) => {
                                            var currently_editing = false
                                            var userThatIsEditing
                                            liveRegistrations.map(item => {
                                                if (item.registrationId === rider.registrationId) {
                                                    currently_editing = true
                                                    userThatIsEditing = item
                                                }
                                            })

                                            var scanTime = new Date((rider.scan.time))

                                            return (
                                                <motion.div
                                                    onClick={() => { openPopup(rider) }}
                                                    variants={tableRowAnimation}
                                                    className='table-row-wrap'
                                                    key={key}
                                                    animate="new"
                                                    exit="remove"
                                                >
                                                    <div className={`box-shadow card table-row ${rider.status !== "active" ? rider.status : ""}`}>
                                                        <div className='scan-data'>
                                                            {scanTime.toLocaleTimeString()}
                                                        </div>

                                                        <div>{rider.rider.name}</div>
                                                        <div>{ConvertToTime(rider.oneLapTime)}</div>
                                                        <div>{rider.motorcycle.cubature} cm3</div>

                                                        <div className='status-icons'>
                                                            <div className={rider.paymentReceived ? "is-true" : ""}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 10.18 18">
                                                                <path id="payment-received" d="M14.96,12.4c-2.27-.59-3-1.2-3-2.15,0-1.09,1.01-1.85,2.7-1.85,1.78,0,2.44.85,2.5,2.1h2.21a3.986,3.986,0,0,0-3.21-3.81V4.5h-3V6.66c-1.94.42-3.5,1.68-3.5,3.61,0,2.31,1.91,3.46,4.7,4.13,2.5.6,3,1.48,3,2.41,0,.69-.49,1.79-2.7,1.79-2.06,0-2.87-.92-2.98-2.1H9.48c.12,2.19,1.76,3.42,3.68,3.83V22.5h3V20.35c1.95-.37,3.5-1.5,3.5-3.55C19.66,13.96,17.23,12.99,14.96,12.4Z" transform="translate(-9.48 -4.5)" fill="#D9D9D9" />
                                                            </svg>
                                                            </div>

                                                            <div className={rider.haveForm ? "is-true" : ""}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.385" height="20" viewBox="0 0 15.385 20">
                                                                    <path id="have-form" d="M15.981,3.375H8.673A1.923,1.923,0,0,0,6.75,5.3V21.452a1.923,1.923,0,0,0,1.923,1.923H20.212a1.923,1.923,0,0,0,1.923-1.923V9.529ZM15.212,10.3V4.913L20.6,10.3Z" transform="translate(-6.75 -3.375)" fill="#ececec" />
                                                                </svg>
                                                            </div>

                                                            <div className={rider.isPresent ? "is-true" : ""}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                    <path id="is-present" d="M13.5,13.5A4.5,4.5,0,1,0,9,9,4.513,4.513,0,0,0,13.5,13.5Zm0,2.25c-2.981,0-9,1.519-9,4.5V22.5h18V20.25C22.5,17.269,16.481,15.75,13.5,15.75Z" transform="translate(-4.5 -4.5)" fill="#ececec" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <AnimatePresence>
                                                        {currently_editing === true &&
                                                            <motion.div
                                                                className="edited-by"
                                                                variants={fadeIn}
                                                                initial="hidden"
                                                                animate="visible"
                                                                exit="hidden"
                                                            >
                                                                {console.log(rider)}
                                                                <div>Práve upravuje: {userThatIsEditing.editingBy}</div>
                                                            </motion.div>}
                                                    </AnimatePresence>
                                                </motion.div>
                                            )
                                        })
                                    }
                                </AnimatePresence>
                            </div>
                        </div>
                            :

                            <div className='event-not-published-notice'>Pretek musí byť aktívny</div>
                        }
                    </div> : <Loading></Loading>}
                </div>
            </div>
        </UserOnline>
    )
}

