import React, { useRef } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router'
import errorIcon from "../images/error_icon-red.svg"
import { FirebaseValidation } from '../contexts/Validation'



export default function LogIn() {

    const [error, seterror] = useState('')
    const [loading, setloading] = useState(false)


    const navigate = useNavigate()
    const emailRef = useRef()
    const passwordRef = useRef()

    const { login, currentUser } = useAuth()


    async function handleSubmit() {
        setloading(true)


        if (!emailRef.current.value) {
            seterror("Je povinné zadať emailovú adresu")
            return
        }

        if (!passwordRef.current.value) {
            seterror("Je povinné zadať heslo")
            return
        }

        login(emailRef.current.value, passwordRef.current.value).then((user) => {
            navigate("/dashboard")

        }).catch((err) => {
            seterror(FirebaseValidation(err.code))
            return
        })

        setloading(false)

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }



    if (!currentUser) {
        return (
            <div className="login-wrap">
                <div className="card login-body">

                    <h1>Prihlásiť sa</h1>
                    {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                    <input className="card box-shadow form-input" placeholder="E-mail" onKeyPress={handleKeyDown} type="email" id="email" ref={emailRef} />
                    <input className="card box-shadow form-input" placeholder="Heslo" onKeyPress={handleKeyDown} type="password" id="password" ref={passwordRef} />
                    <button className="button-primary button-default button-login box-shadow" onClick={() => handleSubmit()}>Prihlásiť sa</button>

                </div>
            </div>
        )
    } else {


        return <Navigate to="/dashboard" />
    }

}
