import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router'
import settingsIcon from '../../images/settings.svg'

export default function SelectEvent() {
    const [displaySelect, setdisplaySelect] = useState(false)
    const navigate = useNavigate()

    //const {setCurrentEvent} = useCurrentEvent()
    var currentId

    if (sessionStorage.getItem("current_event")) {
        currentId = JSON.parse(sessionStorage.getItem("current_event"))

    }

    const { currentUser } = useAuth()
    const data = Object.values(currentUser.data.events)


    var current;

    data.map((item) => {
        if (item.id === currentId) {
            current = item
            return
        }
    })


    function setCurrentEvent(item) {
        sessionStorage.setItem("current_event", JSON.stringify(item.id))
    }


    function HandleSelect(item) {
        setCurrentEvent(item)

        window.location.reload(false);
    }

    function HandleSelectSettings(item) {
        setCurrentEvent(item)
        window.location.reload(false);
        navigate('/dashboard/settings')
    }

    var statusColor = current.status === 'published' ? "green" :
        current.status === 'editable' ? "#EED202" :
            current.status === 'created' ? "#EED202" :
                "#cccccc"


    return (

        <div className="select-event">
            <div key={0} className="current-event-wrap" onClick={() => { setdisplaySelect(!displaySelect) }} >
                <div className="current-event">
                    <div style={{ marginRight: "15px", height: "6px", width: '6px', backgroundColor: statusColor, borderRadius: "50%" }}></div>
                    <div className="name">{current.title} {current.status === "cancelled" ? " - Pretek zrušený" : ""}</div>
                    <div className={`icon ${displaySelect ? "rotate" : ""}`} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.817" height="8.817" viewBox="0 0 12.817 8.817">
                            <g id="arrow_icon" transform="translate(-185.092 -168.092)">
                                <line id="Line_6" data-name="Line 6" x1="5" y1="6" transform="translate(186.5 169.5)" fill="none" stroke="#d33" strokeLinecap="round" strokeWidth="2" />
                                <line id="Line_7" data-name="Line 7" x1="5" y2="6" transform="translate(191.5 169.5)" fill="none" stroke="#d33" strokeLinecap="round" strokeWidth="2" />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <AnimatePresence>
                {displaySelect &&
                    <motion.div
                        initial={{ opacity: 0, y: -100 }}
                        animate={{ opacity: 1, y: -10 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="select-item-dropdown"

                    >
                        <div className="wrap">
                            {data.map((item, i) => {

                                var statusColor = item.status === 'published' ? "green" :
                                    item.status === 'editable' ? "#EED202" :
                                        item.status === 'created' ? "#EED202" :
                                            "#cccccc"

                                return (
                                    <div key={i + 1} onClick={() => { HandleSelect(item) }} className={`event-item-wrap ${item.id === current.id ? 'current' : ''} ${item.status === "cancelled" ? 'cancelled' : ""}`}>
                                        <div className="event-item">
                                            <div style={{ marginRight: "15px", height: "4px", width: '4px', backgroundColor: statusColor, borderRadius: "50%" }}></div>
                                            <div className="event-name">{item.title}</div>

                                            <span className="event-id">{item.id}</span>
                                            <img src={settingsIcon} onClick={() => { HandleSelectSettings(item) }} alt="settings" className='settings-icon' />
                                        </div>
                                    </div>
                                )
                            })}

                            <div key={data.size} className="event-item-wrap" onClick={() => { navigate("/dashboard/add-new") }}>
                                <div className="event-item add-new">
                                    <span className="event-name">Add new</span>
                                    <div className='settings-icon'></div>
                                    <span className="add-new-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                            <g id="plus_icon" transform="translate(-392.5 68.5)">
                                                <line id="Line_8" data-name="Line 8" x2="20" transform="translate(393.5 -57.5)" fill="none" stroke="#d33" strokeLinecap="round" strokeWidth="2" />
                                                <line id="Line_9" data-name="Line 9" y1="20" transform="translate(403.5 -67.5)" fill="none" stroke="#d33" strokeLinecap="round" strokeWidth="2" />
                                            </g>
                                        </svg>

                                    </span>

                                </div>
                            </div>
                        </div>
                    </motion.div>}
            </AnimatePresence>
        </div>
    )


}
