import React from 'react'
import AddNewEventForm from '../AddNewEventForm'
import { useAuth } from '../contexts/AuthContext'
import { Navigate, useNavigate } from 'react-router'
import LoggedInToolbar from '../components/header/LoggedInToolbar'
import goRideLogo from '../images/go_ride_logo_1.svg'


export default function AddEventScreen() {

    const { currentUser } = useAuth()
    const navigate = useNavigate()

    if (currentUser) {

        return (
            <div className="main-component-wraper">

                <div className="add-event-screen main-component">
                    <div className='add-event-wraper'>
                        <div className="wraper">
                            <div className='init-select-header'>
                                <img src={goRideLogo} alt="logo" className='logo' />
                                <LoggedInToolbar></LoggedInToolbar>
                            </div>
                        </div>

                        <div onClick={() => { navigate(-1) }} className="back">Späť</div>

                        <AddNewEventForm></AddNewEventForm>
                    </div>
                </div>
            </div>

        )
    }
    else {

        return (
            <Navigate to={"/prihlasit-sa"}></Navigate>
        )

    }

}
