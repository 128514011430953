import React from 'react'
import { Link } from 'react-router-dom'
import Events from '../components/Events'
import Header from '../components/header/Header'
import Participation from '../components/Participation'
import SendNotification from '../components/SendNotification'
import SidePanel from '../components/SidePanel'
import TimeHarmonogram from '../components/TimeHarmonogram'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export default function DashboardScreen() {

    const navigate = useNavigate()

    return (
        <div className="row dashboard">
            {<SidePanel id={1}></SidePanel>}
            <div className="column content">
                <Header></Header>

                <div className='action-cards'>

                    <div className='card' >
                        <h1>Vitajte v aplikácií Go-Ride 🥂</h1>
                    </div>
                    <div className='card' onClick={() => { navigate("/dashboard/settings") }}>
                        <h1>Nastavenia</h1>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.09 2.91002C10.08 0.900015 7.07 0.490015 4.65 1.67002L8.28 5.30002C8.67 5.69002 8.67 6.32002 8.28 6.71002L6.69 8.30002C6.3 8.70002 5.67 8.70002 5.28 8.30002L1.65 4.67002C0.480003 7.10002 0.890003 10.09 2.9 12.1C4.76 13.96 7.48 14.45 9.79 13.58L17.75 21.54C18.78 22.57 20.44 22.57 21.46 21.54C22.49 20.51 22.49 18.85 21.46 17.83L13.54 9.90001C14.46 7.56001 13.98 4.80002 12.09 2.91002Z" fill="#323232" />
                        </svg>

                    </div>
                    <div className='card' onClick={() => { navigate("/dashboard/jazdci") }}>
                        <h1>Zoznam jazdcov</h1>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.96 11.22C21.57 7.01 17.76 4 13.56 4C13.37 4 13.18 4.01 12.99 4.02C2 4.74 2 17.2 2 17.2V18C2 19.1 2.9 20 4 20H14C18.67 20 22.41 15.99 21.96 11.22ZM5.26 11.56C5.83 10.27 6.54 9.21 7.4 8.37L11.02 9.9C11.62 10.15 12 10.73 12 11.38C12 12.27 11.28 12.99 10.39 12.99H4.72C4.87 12.53 5.04 12.05 5.26 11.56ZM18.44 16.04C17.3 17.29 15.68 18 14 18H4V17.2C4 17.18 4.01 16.28 4.24 15H10.39C12.38 15 14 13.38 14 11.39C14 9.94 13.13 8.63 11.8 8.07L9.3 7.01C10.4 6.44 11.67 6.11 13.12 6.02C13.27 6 13.42 6 13.56 6C16.87 6 19.69 8.37 19.97 11.41C20.13 13.13 19.59 14.77 18.44 16.04Z" fill="#323232" />
                        </svg>
                    </div>
                </div>

                <div className="col">
                    <div className="main-content-wraper">
                        <TimeHarmonogram></TimeHarmonogram>
                    </div>

                    <div className="main-content-wraper">
                        <SendNotification></SendNotification>
                    </div>
                </div>
            </div>
        </div >
    )
}
