import React from 'react';
import './App.css';
import {
  BrowserRouter,
} from "react-router-dom";
import { AuthProvider } from './contexts/AuthContext';
import { Fragment } from 'react';
import { ToastProvider } from './contexts/ToastContext';
import Stripe from './contexts/Stripe';
import CustomRoute from './routes/CustomRoute';
import DeviceSize from './screens/DeviceSize';
import UserOnline from './contexts/UserOnline';
import TeamPopup from './components/popups/TeamPopup';
import { ThemeContext } from './contexts/ThemeContext';


function App() {
  return (

    <>
      <DeviceSize>
        <ThemeContext>
          <BrowserRouter>
            <AuthProvider>
              <Stripe>
                <UserOnline>
                  <ToastProvider>
                    <TeamPopup>
                      <CustomRoute></CustomRoute>
                    </TeamPopup>
                  </ToastProvider>
                </UserOnline>
              </Stripe>
            </AuthProvider>
          </BrowserRouter>
        </ThemeContext>
      </DeviceSize>

    </>
  );
}

export default App;
