import React, { useEffect, useRef, useState } from 'react'
import { getFirestore, getDoc, doc } from 'firebase/firestore'
import errorIcon from '../images/error_icon-red.svg'
import { query, collection, where, getDocs, limit } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useAuth } from '../contexts/AuthContext'

export default function Team() {
    const [teamMembersData, setteamMembersData] = useState([])
    const [loading, setloading] = useState(true)
    const [showAdd, setshowAdd] = useState(false)
    const [error, seterror] = useState()
    const [disabled, setdisabled] = useState(false)
    const [currentEvent, setcurrentEvent] = useState()
    const [data, setdata] = useState()
    const [success, setsuccess] = useState()

    const emailRef = useRef()

    const { currentUser } = useAuth()

    function HandleRemove(id) {
        const functions = getFunctions()
        const sendInvitationEmail = httpsCallable(functions, "removeTeamMember")
        sendInvitationEmail({
            userId: id,
            eventId: currentEvent
        }).then(x => {
            setdisabled(false)
            setshowAdd(false)
            setsuccess("Používateľl úspsne odstránený z teamu")
        })
    }

    function HandleSubmit() {
        seterror("")
        const email = emailRef.current.value
        if (!email) {
            seterror("Chýba emailová adresa")
            return
        }
        const db = getFirestore()

        const collectionRef = collection(db, 'users')

        const q = query(collectionRef, where('email', '==', email), limit(1))
        setdisabled(true)
        getDocs(q).then(snap => {
            if (snap.empty) {
                seterror("Používateľ sa nenašiel")
                setdisabled(false)
            } else {

                snap.docs.forEach(item => {
                    const poi = item.data()

                    if (poi.role !== "admin") {
                        seterror("Používateľ nieje admin")
                        setdisabled(false)
                        return
                    } else {

                        const functions = getFunctions()
                        const sendInvitationEmail = httpsCallable(functions, "sendInvitationEmail")
                        sendInvitationEmail({
                            userId: currentUser.data.userId,
                            invitedUserId: poi.userId,
                            eventId: currentEvent
                        })

                        setdisabled(false)
                        setshowAdd(false)
                        setsuccess("Pozvánka odoslaná")
                        emailRef.current.value = ""


                    }
                })
            }
        })
    }

    function HandleClose() {
        setshowAdd(!showAdd)
        seterror("")
    }

    useEffect(() => {

        async function loadData() {
            const db = getFirestore()
            var currentId = ""
            if (sessionStorage.getItem("current_event")) {
                currentId = JSON.parse(sessionStorage.getItem("current_event"))
            }

            const eventRef = doc(db, `/events_grid/${currentId}/`)
            const snap = await getDoc(eventRef)
            const d = snap.data()
            setdata(d)


            var poi = []
            if (d.team.length > 0) {
                const q = query(collection(db, 'users'), where("userId", 'in', d.team))

                getDocs(q).then(snap => {
                    var poi = []
                    snap.docs.forEach(item => {
                        poi.push(item.data())
                    })
                    setteamMembersData(poi)

                    setTimeout(() => {
                        setloading(false)
                    }, 300);

                }).catch(err => {
                })
            } else {
                setTimeout(() => {
                    setloading(false)
                }, 300);
            }

            setcurrentEvent(currentId)
        }

        loadData()


    }, [])

    return (

        <div className='team'>
            <div className="team-wrap" key={0}>
                <div class="team-member">
                    <img src={`${currentUser.data.avatar ? currentUser.data.avatar : "https://firebasestorage.googleapis.com/v0/b/bikes-planet.appspot.com/o/avatar%2Fdefault_icon.svg?alt=media&amp;token=d0ac3d3c-b196-4eca-a320-4897ff7dc3f8"}`} alt="avatar" class="avatar" />
                    <div class="name">{currentUser.data.firstname} {currentUser.data.lastname} (Vy) </div>
                </div>
            </div>

            {!loading && <>
                {data.team.length > 0 &&
                    teamMembersData.map((item, i) => {
                        return (
                            <div className="team-wrap" key={i + 1}>
                                <div className="team-wrap" key={0}>
                                    <div class="team-member">
                                        <img src={`${item.avatar ? item.avatar : "https://firebasestorage.googleapis.com/v0/b/bikes-planet.appspot.com/o/avatar%2Fdefault_icon.svg?alt=media&amp;token=d0ac3d3c-b196-4eca-a320-4897ff7dc3f8"}`} alt="avatar" class="avatar" />
                                        <div class="name">{item.firstname} {item.lastname}</div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <span>Editor</span>
                                    <div onClick={() => { HandleRemove(item.userId) }}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 8C14 5.79 12.21 4 10 4C7.79 4 6 5.79 6 8C6 10.21 7.79 12 10 12C12.21 12 14 10.21 14 8ZM2 18V19C2 19.55 2.45 20 3 20H17C17.55 20 18 19.55 18 19V18C18 15.34 12.67 14 10 14C7.33 14 2 15.34 2 18ZM18 10H22C22.55 10 23 10.45 23 11C23 11.55 22.55 12 22 12H18C17.45 12 17 11.55 17 11C17 10.45 17.45 10 18 10Z" fill="#dd3333" />
                                        </svg>

                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <div className="team-wrap add">
                    {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                    {success && <div className="success"><div className="success-message">{success}</div></div>}
                    <div className='add-member-form-wrap'>
                        <div className='add-member-form' >
                            <input className='form-input' ref={emailRef} type="text" name="" id="" placeholder='Email' />
                            <button disabled={disabled} onClick={HandleSubmit} className='button-primary'>Poslať pozvánku</button>
                        </div>
                    </div>

                </div>
            </>}

        </div>

    )
}
