import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import clipboardIcon from "../images/clipboard-icon.svg"

export default function CopyToClipboard({children, clip}) {

    const [hover, sethover] = useState(false)

    return (
        <motion.div onHoverStart={()=>{sethover(true)}} onHoverEnd={()=>{sethover(false)}}>
        <div className='copy-to-clipboard'>
                {children}
                <AnimatePresence>
                    { hover && 
                        <motion.div 
                            onClick={() =>  navigator.clipboard.writeText(clip)}
                            initial={{opacity:0}} 
                            animate={{opacity:1}} exit={{opacity:0}} 
                            transition={{duration:0.1}} className='text'
                        > 
                            <img src={clipboardIcon} alt="clipboard" /> 
                            <span>Skopírovať</span>  
                        </motion.div>}
                </AnimatePresence>
        </div>
            
        </motion.div>
    )
}
