import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import CopyToClipboard from '../CopyToClipboard'
import Backdrop from './Backdrop'
import { getFirestore, doc, updateDoc, deleteField } from "firebase/firestore"
import { useToast } from '../../contexts/ToastContext'
import { useAuth } from '../../contexts/AuthContext'
import { motion } from 'framer-motion'
import DisqualifyPopup from './DisqualifyPopup'
import CancelRegistrationPopup from './CancelRegistrationPopup copy'

export default function PopupRiderLive({ ConvertToTime, rider, close, eventData }) {

    const [edited, setedited] = useState(false)
    const [category, setcategory] = useState(rider.category)
    const [haveForm, sethaveForm] = useState(rider.haveForm)
    const [paymentDetails, setpaymentDetails] = useState({
        paymentMethod: rider.paymentMethod,
        paymentReceived: rider.paymentReceived,
        ...rider.paymentDetails
    })

    const [displayActions, setdisplayActions] = useState(false);
    const [disqualifyPopup, setdisqualifyPopup] = useState(false);
    const [cancellRegistrationPopup, setCancellRegistrationPopup] = useState(false);

    const displayToast = useToast()
    const { currentUser } = useAuth()

    const startNumberRef = useRef()
    const lapTimeRef = useRef()
    const notesRef = useRef()


    const toolbarAnimate = {
        enter: {
            opacity: 1,
            rotateX: 0,
            transition: {
                duration: 0.2
            },
            display: "block"
        },
        exit: {
            opacity: 0,
            rotateX: -15,
            transition: {
                duration: 0.2,
                delay: 0.1
            },
            transitionEnd: {
                display: "none"
            }
        }
    };

    const db = getFirestore()

    const dropIn = {
        hidden: {
            y: "-50vh",
            opacity: "0"
        },
        visible: {
            y: "0",
            opacity: "1",
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 40,
                stiffness: 700,
            }
        },
        exit: {
            y: "-100vh",
            transition: { duration: 0.1 }

        },
    }

    function toggleHaveForm() {
        sethaveForm(!haveForm)
    }

    function HandleUpdate() {
        var poi = rider
        poi.category = category
        poi.startNumber = startNumberRef.current.value
        poi.notes = notesRef.current.value
        poi.oneLapTime = lapTimeRef.current.value
        poi.haveForm = haveForm
        if (rider.paymentMethod === "hotovost" && rider.paymentReceived !== paymentDetails.paymentReceived) {
            poi.paymentReceived = true
            poi.paymentDetails = {
                amount: eventData.price * 100,
                date: new Date().getTime() / 1000,
                receivedBy: {
                    name: `${currentUser.data.firstname} ${currentUser.data.lastname}`,
                    id: currentUser.data.userId,
                }
            }
        }
        poi.live.status = "done"

        const time = poi.oneLapTime.split(':')
        var min = time[0]
        var sec = time[1]

        min = parseInt(min)
        sec = parseInt(sec)
        poi.oneLapTime = min * 60 + sec
        //poi.live = deleteField()


        const docRef = doc(db, `/events_grid/${rider.eventId}/registrations/${rider.registrationId}`)
        updateDoc(docRef, poi).then(() => {
            close()
            displayToast("Uložené", "success")
        }).catch(err => {
            displayToast("Chyba", "error")
        })
    }


    function PaymentReceived() {
        const paymentData = {
            paymentReceived: true,
            amount: eventData.price * 100,
            date: new Date().getTime() / 1000,
            receivedBy: {
                name: `${currentUser.data.firstname} ${currentUser.data.lastname}`,
                id: currentUser.data.userId,
            },
        }
        setpaymentDetails(paymentData)
    }


    useEffect(() => {
        console.log(category, startNumberRef.current.value, haveForm, paymentDetails.paymentReceived)
        if (category !== "" && startNumberRef.current.value !== "" && haveForm === true && paymentDetails.paymentReceived === true) {
            setedited(true)
        } else {
            setedited(false)
        }
    }, [haveForm, category, startNumberRef, paymentDetails])


    return (

        <Backdrop onClick={() => { close() }}>
            <motion.div
                onClick={(e) => { e.stopPropagation() }}
                className="modal"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >

                <div className="popup-wrap">
                    <div className="card popup-body">
                        <AnimatePresence exitBeforeEnter>

                            {disqualifyPopup && <DisqualifyPopup rider={rider} event={eventData} closePopup={close} close={() => { setdisqualifyPopup(false) }}></DisqualifyPopup>}
                            {cancellRegistrationPopup && <CancelRegistrationPopup rider={rider} event={eventData} closePopup={close} close={() => { setCancellRegistrationPopup(false) }} />}

                        </AnimatePresence>

                        <div className="popup-header">
                            <h1>{rider.rider.name}</h1>

                            <motion.div onHoverEnd={() => setdisplayActions(false)} className="more-wraper" whileHover={() => setdisplayActions(true)} >
                                <div className='more-icon'>
                                    <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z" stroke="black" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16 3C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3Z" stroke="black" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z" stroke="black" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>

                                <AnimatePresence>
                                    {displayActions && <div className="toolbar-gap">
                                        <motion.div
                                            initial="exit"
                                            animate="enter"
                                            exit={"exit"}
                                            variants={toolbarAnimate}
                                            className="more-dropdown">
                                            <div className="wraper box-shadow">
                                                <div className="item" onClick={() => setdisqualifyPopup(true)}>Diskvalifikovať</div>
                                                <div className="item" onClick={() => setCancellRegistrationPopup(true)}>Zrušiť registráciu</div>
                                                <div className="item">Neprítomný</div>
                                            </div>
                                        </motion.div>


                                    </div>}
                                </AnimatePresence>
                            </motion.div>

                            <div className="close" onClick={() => { close() }} >

                                <svg xmlns="http://www.w3.org/2000/svg" className='close-icon' viewBox="0 0 48 48">
                                    <g id="cancel" transform="translate(0 -0.001)">
                                        <g id="Group_104" data-name="Group 104" transform="translate(0 0.001)">
                                            <path id="Path_28" data-name="Path 28" d="M26.652,24l20.8-20.8A1.875,1.875,0,0,0,44.8.55L24,21.349,3.2.55A1.875,1.875,0,0,0,.549,3.2L21.348,24,.549,44.8A1.875,1.875,0,0,0,3.2,47.452L24,26.653l20.8,20.8A1.875,1.875,0,1,0,47.451,44.8Z" transform="translate(0 -0.001)" fill="#fff" />
                                        </g>
                                    </g>
                                </svg>

                            </div>
                        </div>

                        <div className="basic-info">

                            <div className="popup-row">
                                <span className="title">Tel.</span>
                                <CopyToClipboard clip={rider.rider.phoneNumber}>
                                    <span className="content">{rider.rider.phoneNumber}</span>
                                </CopyToClipboard>
                            </div>

                            <div className="popup-row">
                                <span className="title">E-mail</span>
                                <CopyToClipboard clip={rider.rider.email}>
                                    <span className="content">{rider.rider.email}</span>
                                </CopyToClipboard>
                            </div>
                        </div>

                        <div className="card popup-actions-wrap">
                            <form>
                                <div className="actions-content">
                                    <div><label htmlFor="startNumber">Číslo</label> <input className="form-input" ref={startNumberRef} type="number" id="startNumber" defaultValue={rider.startNumber} /></div>
                                    <div><label htmlFor="category">Kategória</label>

                                        <select name="category" onChange={(e) => { setcategory(e.target.value) }} id="cat-select" className='form-input' defaultValue={rider.category} >
                                            <option value="">Vyberte...</option>
                                            {eventData.category.map(item => (
                                                <option value={item.shortenName}>{item.fullName}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="oneLapTime">Čas j. kola</label>
                                        <input className="form-input" ref={lapTimeRef} type="text" id="oneLapTime" defaultValue={ConvertToTime(rider.oneLapTime)} />
                                    </div>

                                </div>

                                <div className="popup-columns-wrap">
                                    <div className="popup-note-column">

                                        <label htmlFor="input-notes">Poznámky</label>
                                        <textarea cols="15" rows="8" id="notes" ref={notesRef} placeholder="Poznámky" defaultValue={rider.notes}></textarea>

                                    </div>
                                    <div className="popup-present-column" >
                                        {/*console.log(haveForm, "INITIAL HAVE FORM")*/}
                                        <div onClick={() => { toggleHaveForm() }} className={`${haveForm ? "is-true" : ""} popup-present-wraper`}>
                                            <div >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.385" height="20" viewBox="0 0 15.385 20">
                                                    <path id="have-form" d="M15.981,3.375H8.673A1.923,1.923,0,0,0,6.75,5.3V21.452a1.923,1.923,0,0,0,1.923,1.923H20.212a1.923,1.923,0,0,0,1.923-1.923V9.529ZM15.212,10.3V4.913L20.6,10.3Z" transform="translate(-6.75 -3.375)" fill="#ececec" />
                                                </svg>
                                            </div>
                                            <div className='title' >Tlačivo</div>
                                        </div>

                                        <div className='payment-info'>
                                            {!paymentDetails.paymentReceived ?
                                                <div>
                                                    <div className='payment-method'>Platba v hotovosti</div>
                                                    <motion.button onClick={(e) => { PaymentReceived() }} className="button-primary">Prevziať</motion.button>

                                                </div> :

                                                <div>
                                                    {paymentDetails.paymentMethod === "karta" ? <a className='payment-method' target="_blank" href={`https://dashboard.stripe.com/test/payments/${rider.paymentDetails.id}`}>Platba Kartou</a> : <div className='payment-method'>Platba v hotovosti</div>}
                                                    <div><b>Platba prijatá: </b>{new Date(paymentDetails.date * 1000).toLocaleString()}</div>
                                                    {paymentDetails.paymentMethod !== "karta" && <div><b>Prevzal: </b>{paymentDetails.receivedBy.name}</div>}
                                                    <div><b>Suma: </b>{paymentDetails.amount / 100} €</div>
                                                </div>
                                            }
                                        </div>

                                    </div>

                                </div>
                            </form>

                        </div>

                        <div className="buttons-wrap">
                            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.98 }} disabled={!edited} onClick={() => { HandleUpdate() }} className="button-primary">Uložiť zmeny</motion.button>
                            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.98 }} onClick={() => { close() }} className="button-default">Zrušiť</motion.button>
                        </div>
                    </div>
                </div>
            </motion.div>


        </Backdrop>

    )


}



