import React, { useEffect, useState } from 'react'
//import firebase from 'firebase'

export default function Events() {

    const [events, setEvents] = useState([])
    const [loading, setloading] = useState(false)

    return (

        <div>
            <div className="main-content card">

                <div className="events-heading">Udalosti</div>

                <div className="button-wrap">
                    <button className="button-primary button-default">Zobraziť viac</button>
                </div>

            </div>
        </div>
    )
}
