import React from 'react'
import { useNavigate } from 'react-router'
import LoggedInToolbar from '../components/header/LoggedInToolbar'
import { useAuth } from '../contexts/AuthContext'
import goRideLogo from '../images/go_ride_logo_1.svg'

export default function NotAdminScreen() {
    const { logout } = useAuth()
    const navigate = useNavigate()
    const { currentUser } = useAuth()

    return (
        <div className="main-component-wraper">
            <div className='not-admin-page main-component'>
                <div className="wraper">
                    <div className='init-select-header'>
                        <img src={goRideLogo} alt="logo" className='logo' />
                        <LoggedInToolbar></LoggedInToolbar>
                    </div>
                    <div style={{ marginTop: "15%" }}>
                        <h1>Stránka prístupná len pre administrátora</h1>
                        <p>Váš účet nemá prístup k administrácií preteku. Skúste iný účet alebo si zakúpte Pro</p>
                        <div className="buttons">
                            <button className='button-primary' onClick={() => { logout(); window.location.reload(false); }}>Odhlásiť sa</button>
                            <button className='button-primary' onClick={() => { navigate("/checkout") }}>Kúpiť</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
