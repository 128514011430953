import React, { useEffect, useRef, useState } from 'react';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, doc, updateDoc } from 'firebase/firestore'
import { AnimatePresence, motion } from 'framer-motion';
import Resizer from "react-image-file-resizer";
import { getFunctions, httpsCallable } from 'firebase/functions'
import errorIcon from '../images/error_icon-red.svg'


export default function UploadImage({ data }) {
    const [file, setfile] = useState();
    const [progress, setprogress] = useState();
    const [avatarChanged, setavatarChanged] = useState(false);
    const fileRef = useRef();
    const [loading, setloading] = useState(false);
    const [error, seterror] = useState("");

    const { currentUser } = useAuth()
    const db = getFirestore()
    const docRef = doc(db, `/events_grid/${data.eventId}/`)
    const [selectedAvatar, setselectedAvatar] = useState(data.details.image);
    const [imagePreview, setimagePreview] = useState(data.details.image);

    const supported_formats = [
        'png',
        'PNG',
        'jpg',
        "JPG",
        'jpeg',
        'JPEG',
        'svg',
        'SVG'
    ]

    const resizeFile = async (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1920,
                1080,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    function loadFile(e) {

        e.preventDefault()
        seterror("")
        const file = e.target[0].files[0]

        uploadFile(selectedAvatar)
    }

    function uploadFile(file) {
        seterror("")
        if (!file) {
            seterror("Nieje zvolený žiaden súbor")
            return
        }

        setloading(true)
        const storage = getStorage()
        const storageRef = ref(storage, `/events/${currentUser.data.userId}/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on("state_changed", (snapshot) => {
            const prog = Math.trunc((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            setprogress(prog)

        }, (err) => {
            seterror(err)
        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {

                updateDoc(docRef, {
                    details: {
                        image: url
                    }
                }).then(() => {
                    setavatarChanged(false)
                    setloading(false)
                }).catch(err => {
                    seterror(err)
                    setloading(false)
                })

            })
        })
    }

    async function setnewImage(e) {
        seterror("")
        if (!e.target.files[0]) return

        if (!e.target.files[0].type.startsWith("image")) {
            seterror("Nepodporovaný formát")
            setavatarChanged(false)
            return
        } else {
            const new_avatar = await resizeFile(e.target.files[0])
            const avatar_uri = URL.createObjectURL(new_avatar)
            setselectedAvatar(new_avatar)
            setimagePreview(avatar_uri)

            setavatarChanged(true)
        }
    }

    return (
        <div className='upload-image'>
            <form onSubmit={loadFile}>
                <div className="avatar-wrap">

                    <div>
                        <img alt="avatar" className='preview' src={imagePreview} />
                        <label htmlFor="file" className='camera-icon-wrap' >
                        </label>
                    </div>

                    <input type="file" name="file" id="file" onChange={(e) => { setnewImage(e) }} />
                    <AnimatePresence>
                        {avatarChanged &&

                            <motion.button
                                disabled={loading}
                                type="submit"
                                className='button-primary'
                                initial={{ y: -20, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -20, opacity: 0 }}
                                disabled={loading}
                            >{
                                    !loading ? "Uložiť" : "Ukladám..."}</motion.button>
                        }
                    </AnimatePresence>
                    {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                </div>
            </form>
        </div>
    );

}