import React, { useEffect, useState } from 'react'
import CategoryItem from './CategoryItem'

export default function AddCategory({ disabled, removeItem, items, setItems, error }) {

    function onChange(data, i) {
        var dataToUpdate = items
        dataToUpdate[i].fullName = data.fullName
        dataToUpdate[i].shortenName = data.shortenName
        setItems(dataToUpdate)

    }


    function addItem() {
        if (items) {
            setItems([...items, { fullName: "", shortenName: "", id: items.length + 1, }])
        } else {
            setItems([{ title: "", id: 1, start: 0, end: 0 }])
        }


    }

    function removeItem(item) {
        var filtered = items.filter(function (value, index, arr) {
            return index !== item;
        });

        setItems(filtered)
    }



    return (
        <div className='add-harmonogram category'>
            <h1>Kategórie</h1>
            {error.message && <span className='form-validation-error'>{error.message}</span>}
            {items ? <div className='harmonogram-items' >
                <div>
                    <div className='harmonogram-item-wrap header'>
                        <span type="text" className='title' >Krátky názov</span>
                        <span type="text" className='title' >Celý názov</span>
                    </div>
                    {items.map((item, i) => (
                        <div key={item.id}>
                            <CategoryItem disabled={disabled} removeItem={removeItem} error={error.id === i ? true : false} item={item} i={i} update={onChange}></CategoryItem>
                        </div>
                    ))}
                </div>

            </div> :

                <div>Žiadne dáta</div>

            }

            {!disabled && <button onClick={addItem} className="add-new-item">
                <span className='plus'>+</span>
                <span className='text'>Pridať</span>
            </button>}

        </div>
    )
}
