import React from 'react'

export function FirebaseValidation(error) {
    if (error === "auth/invalid-email") return "Emailová adresa nieje správna"
    if (error === "auth/user-disabled") return "Používateľ zablokovaný"
    if (error === "auth/user-not-found") return "Používateľ sa nenašiel"
    if (error === "auth/wrong-password") return "Nesprávne heslo"
    if (error === "auth/too-many-requests") return "Príliš veľa nesprávnych pokusov o prihlásenie"
    if (error === "auth/email-already-in-use") return "Tento email sa už používa"
    if (error === "auth/weak-password") return "Heslo je príliš slabé (minimálne 6 znakov)"

    return error

}

export function ValidateNewAccount(firstname, lastname, email, tel, password, repeatpassword) {

    if (!firstname) return "Meno je povinné vyplniť"
    if (!lastname) return "Priezvisko je povinné vyplniť"
    if (!email) return "Email je povinné vyplniť"
    if (!email.includes("@") || !email.includes(".")) return "Email je v nesprávnom tvare"
    if (!tel) return "Je povinné vyplniť telefónne číslo"
    if (!password) return "Heslo je povinné vyplniť"
    if (!repeatpassword) return "Je povinné overiť heslo"
    if (password !== repeatpassword) return "Heslá sa nezhodujú"

    return
}


export function ValidateAccountUpdate(d, m, y, model, cubature) {
    if (!model) return "Je povinné zadať model motocyklu"
    if (!cubature) return "Je povinné zadať kumatúru"

    if (isNaN(cubature)) return "Neplatný tvar kubatúry"

}



export function ValidateDateOfBirth(d, m, y) {

    // Sú zadané hodnoty?
    if (!d) return "Deň je povinné vyplniť"
    if (!m) return "Mesiac je povinné vyplniť"
    if (!y) return "Rok je povinné vyplniť"

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    //Prestupný rok
    if (y % 400 == 0 || (y % 100 != 0 && y % 4 == 0))
        monthLength[1] = 29;


    // je to číslo?

    if (isNaN(d)) return "Deň \"" + d + "\" neexistuje"
    if (isNaN(m)) return "mesiac \"" + m + "\" neexistuje"
    if (isNaN(y)) return "rok \"" + y + "\" neexistuje"

    const currentDate = {
        d: new Date().getDay(),
        m: new Date().getMonth(),
        y: new Date().getFullYear(),
    }

    if (d <= 0 || d > monthLength[m - 1]) return "Dátum \"" + d + "." + m + "." + y + "\" neexistuje"
    if (y < 1000 || y > currentDate.y || y < currentDate.y - 100 || m == 0 || m > 12) return "Neplatný dátum \"" + d + "." + m + "." + y + "\""

    //Overenie vytvoren=im objedku
    const x = Date.parse(y + "-" + m + "-" + d)
    if (x === "Nan") return "Neznáma chyba, kontaktujte podporu (dátum)"

    return
}

export function ValidateDate(d, m, y) {

    // Sú zadané hodnoty?
    if (!d) return "Deň je povinné vyplniť"
    if (!m) return "Mesiac je povinné vyplniť"
    if (!y) return "Rok je povinné vyplniť"

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    //Prestupný rok
    if (y % 400 == 0 || (y % 100 != 0 && y % 4 == 0))
        monthLength[1] = 29;


    // je to číslo?

    if (isNaN(d)) return "Deň \"" + d + "\" neexistuje"
    if (isNaN(m)) return "mesiac \"" + m + "\" neexistuje"
    if (isNaN(y)) return "rok \"" + y + "\" neexistuje"

    const currentDate = {
        d: new Date().getDay(),
        m: new Date().getMonth(),
        y: new Date().getFullYear(),
    }

    if (d <= 0 || d > monthLength[m - 1]) return "Dátum \"" + d + "." + m + "." + y + "\" neexistuje"
    if (m === 0 || m > 12) return "Neplatný dátum \"" + d + "." + m + "." + y + "\""

    //Overenie vytvoren=im objedku
    const x = Date.parse(y + "-" + m + "-" + d)
    if (x === "Nan") return "Neznáma chyba, kontaktujte podporu (dátum)"

    return
}


export function validateHarmonogram(item, i) {
    const title = item.title
    const start = item.start
    const end = item.end


    if (!title || title === "") {
        return { message: "Chýba meno", "id": i }

    }
    if (!start || start === 0) {
        return { message: "Chýba čas začiatku", id: i }
    }
    if (!end || end === 0) {
        return { message: "Chýba čas konca", id: i }

    }

    return 0
}

export function validateCategory(item, i) {
    const fullName = item.fullName
    const shortenName = item.shortenName


    if (!fullName || fullName === "") {
        return { message: "Chýba celý názov kategórie", "id": i }

    }
    if (!shortenName || shortenName === "") {
        return { message: "Chýba krátky názov kategórie", id: i }
    }
    return 0

}

export function ValidateTitle(title) {
    if (!title || title === "") {
        return "Názov preteku je povinné pole"
    }
    return ""
}

export function ValidateEventDate(date) {

    if (!date || date === "" || date === 0) {
        return "Dátum preteku je povinné pole"
    }

    var d, m, y;

    var arr = date.split("-")
    if (arr.lenght < 3) {
        return "Dátum v neplatnom formáte"
    }

    d = arr[0]
    m = arr[1]
    y = arr[2]

    const datevalidation = ValidateDate(y, m, d)
    if (datevalidation) {
        return datevalidation
    }
    var now = new Date()
    now = Math.floor(now / 1)

    if (Date.parse(date) <= now) {
        return "Neplatný dátum - dátum konania nemôže byť v minulosti."
    }

    var time_in_5_years = new Date().setFullYear(new Date().getFullYear() + 5);

    if (Date.parse(date) <= now) {
        return "Neplatný dátum - dátum konania nemôže byť v minulosti."
    }

    if (Date.parse(date) >= time_in_5_years) {
        return "Neplatný dátum - Plánovať pretek je možné len na 5 rokov dopredu."
    }
    return ""

}

export function ValidateLocation(location) {
    if (!location || location === "") {
        return "Miesto konania je povinné pole"
    }
    return ""
}

export function ValidateDifficulty(difficulty) {
    if (!difficulty || difficulty === "") {
        return "Obtiažnosť je povinné pole"
    }

    if (difficulty <= 0 || difficulty > 5) {
        return "Údaj obtiažnosti môže byť len číslo od 1 (najľahšia) do 5 (najťažšia)"
    }

    return ""
}

export function ValidatePrice(price) {
    price = price * 1
    if (!price || price === "" || price === 0) {
        return "Cenu je povinné zadať"
    }

    return ""
}