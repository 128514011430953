import React from 'react'

import useWindowDimensions from '../hooks/useWindowDimensions'
import goRideLogo from '../images/go_ride_logo_1.svg'
import deviceNotSupported from '../images/device-not-supported.svg'

export default function DeviceSize({ children }) {
    const { height, width } = useWindowDimensions()

    if (width < 1080 || height < 700) {
        return (
            <div className="not-device-wrap">
                <div className="not-device">
                    <img src={deviceNotSupported} alt="logo" className='icon' />

                    <div className='text'>Aplikácia neiej podporovaná pre rozmer vášho zariadenie</div>
                    <img src={goRideLogo} alt="logo" className='logo' />
                </div>
            </div>

        )
    }

    return (
        <>{children}</>
    )
}
