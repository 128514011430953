import React, { useState } from 'react'
//import {firestore} from '../firebase.js'


export default function PopupBox({ box, close, popup, updateData }) {

    const [edited, setedited] = useState(false)
    const [error, setError] = useState("")
    const [errorName, setErrorName] = useState("")



    const state = {
        boxId: box.boxId,
        riderName: box.riderName,
        payment: box.payment,
        //rentDate:box.rentDate,
        isRented: box.isRented,
        notes: box.notes,
        //returnedAt:box.returnedAt,

    }


    function handleOnChange(event) {
        setedited(true)
        state[event.target.id] = event.target.value

    }

    function onClose() {

        if (edited) {
            alert("Chcete naozaj uložiť bez zmien?")
        }
        setedited(false)
        setErrorName("")
        close()
    }

    function onUpdate(e) {
        e.preventDefault()


        onClose()

    }

    function validation(state) {

        var result = {
            nameerror: "",
            paymenterror: "",
        }

        if (!state.riderName) {
            result.nameerror = "Prázdna meno"
        }
        if (!state.payment) {
            result.paymenterror = "Prázdna platba"
        }

        if (result.nameerror || result.paymenterror) {
            return result;
        }

        return true
    }


    function onRent(e) {
        e.preventDefault()



    }

    function onBack(e) {
        e.preventDefault()
        /*         setError("")
        if(error !=="")
        {           
                         return            
        }
        else{
            const docRef = db.collection('boxes').doc(`BOX-${state.boxId}`)
            const newData ={

                wasGivenBack: new Date(),
                ...state
            }
            newData.isRented="IS_GIVEN_BACK"
            
            docRef.update(newData)
            
            onClose()
        }*/
    }



    if (!popup) return null;

    return (
        <div className=" popup-wrap">
            <div className="card popup-body popup-body-box">
                <div className="error">{error}</div>
                <div className="popup-header">
                    <h1>BOX - {box.boxId}</h1>
                    <span className="close" onClick={onClose} >Close</span>
                </div>


                <div className="card popup-actions-wrap">
                    {box.isRented === "NOT_RENTED" &&
                        <form>
                            <div className="actions-content">

                                <div>
                                    <label htmlFor="riderName">Meno</label>
                                    <input

                                        required
                                        defaultValue={box.riderName}
                                        onChange={handleOnChange}
                                        onInput={handleOnChange}
                                        className="card box-shadow"
                                        type="text"
                                        id="riderName" />
                                    <div className="error">{errorName}</div>
                                </div>

                            </div>

                            <div className="popup-columns-wrap">
                                <div className="popup-note-column">
                                    <textarea
                                        rows="4"
                                        id="notes"
                                        laceholder="Poznámky"
                                        defaultValue={box.notes}
                                        onInput={handleOnChange} >
                                    </textarea>
                                </div>

                                <div className="popup-box-column">
                                    <div className="box-input">
                                        <label htmlFor="payment">Platba</label>
                                        <input
                                            defaultValue={box.payment}
                                            onInput={handleOnChange}
                                            className="card box-shadow"
                                            type="text"
                                            id="payment" />
                                    </div>
                                    <button className="button-primary button-default" onClick={onRent} >Rezervovať</button>


                                </div>
                            </div>
                        </form>}


                    {box.isRented === "IS_RENTED" &&
                        <form>
                            <div className="actions-content">
                                <span><b>Meno:</b> {' ' + box.riderName}</span>
                            </div>
                            <div className="popup-columns-wrap">
                                <div className="popup-note-column">

                                    <textarea rows="4" id="notes" placeholder="Poznámky" defaultValue={box.notes} onInput={handleOnChange} ></textarea>

                                </div>

                                <div className="popup-box-column">
                                    <div className="box-input"><label htmlFor="payment">Platba</label> <input defaultValue={box.payment} onInput={handleOnChange} className="card box-shadow" type="text" id="payment" /></div>
                                    <button className="button-primary button-default" disabled={box.isRented === "NOT_RENTED"} onClick={onBack} >Vrátiť</button>

                                </div>
                            </div>
                        </form>}

                    {box.isRented === "IS_GIVEN_BACK" &&
                        <form>
                            <div className="actions-content">
                                <span><b>Meno:</b> {' ' + box.riderName}</span>
                            </div>
                            <div className="popup-columns-wrap">
                                <div className="popup-note-column">

                                    <textarea rows="4" id="notes" placeholder="Poznámky" defaultValue={box.notes} onInput={handleOnChange} ></textarea>

                                </div>

                                <div className="popup-box-column">
                                    <div className="box-input"><label htmlFor="payment">Platba</label> <input defaultValue={box.payment} onInput={handleOnChange} className="card box-shadow" type="text" id="payment" /*defaultValue={rider.category}*/ /></div>
                                    <button className="button-primary button-default" disabled={box.isRented === "NOT_RENTED"} onClick={onBack} >Obnoviť</button>

                                </div>
                            </div>
                        </form>}


                </div>
                <div className="buttons-wrap">

                    {box.isRented === "IS_RENTED" && <button className="button-primary button-default" onClick={onUpdate} >Uložiť</button>}
                    <button className="button-default" onClick={onClose}>Zrušiť</button>
                </div>
            </div>


        </div>
    )
}



