import React, { useContext, useEffect, useState } from 'react'
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { initializeApp } from '@firebase/app';
import { doc, getDoc, getFirestore } from '@firebase/firestore';
import Loading from '../components/Loading';

const MyContext = React.createContext()


async function login(email, password) {

    const auth = getAuth()
    const ret = await signInWithEmailAndPassword(auth, email, password)
    return ret
}


export function logout() {
    const auth = getAuth()
    signOut(auth).then(() => {
        sessionStorage.removeItem("current_event")
        return "success"
    }).catch((err) => {
        return err
    })
}


export function AuthProvider(props) {
    const [loading, setloading] = useState(true)
    const [currentUser, setcurrentUser] = useState()
    const [user, setuser] = useState()


    useEffect(() => {
        initializeApp({
            apiKey: process.env.REACT_APP_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_APPID,
            databaseURL: process.env.REACT_APP_DATABASE_URL,
        })


        getAuth().onAuthStateChanged(user => {

            if (user) {
                const db = getFirestore()

                const docRef = doc(db, "users", user.uid)

                getDoc(docRef).then((data) => {

                    setcurrentUser({ user, "data": data.data() })
                    setloading(false)
                })
            }
            else {
                setloading(false)
            }

        })


    }, [])

    const value = {
        currentUser,
        login,
        logout
    }

    return (

        <MyContext.Provider value={value}>
            <>
                {!loading ? props.children : <Loading />}

            </>
        </MyContext.Provider>
    )
}

export function useAuth() {
    return useContext(MyContext)
}