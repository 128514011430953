import { Clock } from './Clock'
import TimeHarmonogramTable from './TimeHarmonogramTable'


export default function TimeHarmonogram() {

    return (

        <div className="main-content">
            <Clock></Clock>
            <br></br> <span className="main-window-date">Počasie</span>
            <div className="card time-harmonogram-content">
                <h1>Časový harmonogram</h1>

                <TimeHarmonogramTable></TimeHarmonogramTable>
            </div>

        </div>
    )
}
