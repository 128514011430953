import React, { useEffect, useState } from 'react'
import HarmonogramItem from './HarmonogramItem'

export default function AddtimeHarmonogram({ disabled, removeItem, items, setItems, error }) {

    function onChange(data, i) {
        var dataToUpdate = items
        dataToUpdate[i].title = data.title
        dataToUpdate[i].start = data.start
        dataToUpdate[i].end = data.end
        setItems(dataToUpdate)

    }


    function addItem() {
        if (items) {
            setItems([...items, { title: "", id: items.length + 1, start: 0, end: 0 }])
        } else {
            setItems([{ title: "", id: 1, start: 0, end: 0 }])
        }


    }

    function removeItem(item) {
        var filtered = items.filter(function (value, index, arr) {
            return index !== item;
        });

        setItems(filtered)
    }



    return (
        <div className='add-harmonogram'>
            <h1>Časový harmonogram</h1>
            {error.message && <span className='form-validation-error'>{error.message}</span>}
            {items ? <div className='harmonogram-items' >
                <div className='harmonogram-item-wrap header' key={0}>
                    <div></div>
                    <div>Názov</div>
                    <div>Začiatok a koniec</div>

                    <div></div>
                </div>
                <div>
                    {items.map((item, i) => (
                        <div key={item.id} value={item}>
                            <HarmonogramItem disabled={disabled} removeItem={removeItem} error={error.id === i ? true : false} item={item} i={i} update={onChange}></HarmonogramItem>
                        </div>
                    ))}
                </div>

            </div> :

                <div>Žiadne dáta</div>

            }

            {!disabled && <button onClick={addItem} className="add-new-item">
                <span className='plus'>+</span>
                <span className='text'>Pridať</span>
            </button>}

        </div>
    )
}
