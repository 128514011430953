import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/header/Header'
import SidePanel from '../components/SidePanel'
import { getFirestore, doc, getDoc, updateDoc, writeBatch, arrayUnion } from "firebase/firestore"
import { useAuth } from '../contexts/AuthContext'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill'; // ES6
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router'
import errorIcon from "../images/error_icon.svg"
import { useToast } from '../contexts/ToastContext'
import { getFunctions, httpsCallable } from "firebase/functions";
import AddtimeHarmonogram from '../components/time-harmonogram/AddtimeHarmonogram'
import UploadEventImg from '../components/UploadEventImg'
import { validateCategory, ValidateDifficulty, ValidateEventDate, validateHarmonogram, ValidateLocation, ValidatePrice, ValidateTitle } from '../contexts/Validation'
import Team from '../components/Team'
import AddCategory from '../components/category/AddCategory'
import Loading from '../components/Loading'


export default function SettingsScreen() {


    const [editable, seteditable] = useState(true)
    const [loading, setloading] = useState(true)
    const [data, setdata] = useState()
    const [items, setItems] = useState([])
    const [categoryItems, setcategoryItems] = useState([])

    const { currentUser } = useAuth()
    const [text, settext] = useState({ text: "" })

    const [statusColor, setstatusColor] = useState()

    // Validation 
    const [harmonogramError, setHarmonogramError] = useState({ message: "", id: null })
    const [titleError, settitleError] = useState("")
    const [dateError, setdateError] = useState("")
    const [locationError, setlocationError] = useState("")
    const [difficultyError, setdifficultyError] = useState("")
    const [priceError, setpriceError] = useState("")
    const [isError, setisError] = useState(false)
    const [categoryError, setcategoryError] = useState("")

    const eventTitleRef = useRef()
    const dateRef = useRef()
    const locationRef = useRef()
    const difficultyRef = useRef()
    const priceRef = useRef()
    const harmonogramRef = useRef()
    const categoryRef = useRef()


    const displayToast = useToast()


    const currentEvent = JSON.parse(sessionStorage.getItem("current_event"))

    useEffect(() => {
        const db = getFirestore();
        const docRef = doc(db, "events_grid", currentEvent);

        getDoc(docRef).then(data => {

            var poi = data.data()
            poi.eventId = data.id
            settext(poi.details.description)

            const x = new Date(poi.date)

            var day = ('0' + x.getDate()).slice(-2);
            var month = ('0' + (x.getMonth() + 1)).slice(-2);
            var year = x.getFullYear();


            poi.date = year + '-' + month + '-' + day;
            setdata(poi)
            setItems(poi.harmonogram)
            setcategoryItems(poi.category)


            setloading(false)

            if (poi.status === "published" || poi.status === "cancelled") {
                seteditable(false)
            }

            setColor(poi)
        })

    }, [])


    function setColor(data) {
        setstatusColor(data.status === 'published' ? "#4bb543" :
            data.status === 'editable' ? "#EED202" :
                data.status === 'created' ? "#EED202" :
                    "#cccccc")
    }

    function DeleteEvent() {

        if (window.confirm(`Naozaj chcete vymazať event?`)) {

            const db = getFirestore()
            const eventRef = doc(db, `events_grid/${currentEvent}/`)
            const userRef = doc(db, "users", currentUser.data.userId)

            const batch = writeBatch(db)
            const arr = currentUser.data.events

            arr.map((item, i) => {
                if (currentEvent === item.id) {

                    arr.splice(i, 1)
                }
            })


            batch.delete(eventRef)
            batch.update(userRef, { "events": arr })


            batch.commit().then((snap) => {

                localStorage.removeItem("current_event")
                window.location.reload(false);

            }).catch((err) => {
                console.error(err)
            })
        }
    }


    function HandleEdit(publish) {

        const eventTitle = eventTitleRef.current.value
        const date = dateRef.current.value
        const location = locationRef.current.value
        const difficulty = parseInt(difficultyRef.current.value)
        const price = priceRef.current.value

        settitleError("")
        setdateError("")
        setlocationError("")
        setdifficultyError("")
        setpriceError("")
        setHarmonogramError({ message: "", id: null })
        setisError(false)

        const vTitle = ValidateTitle(eventTitle)
        const vDate = ValidateEventDate(date)
        const vLocation = ValidateLocation(location)
        const vDifficulty = ValidateDifficulty(difficulty)
        const vPrice = ValidatePrice(price)


        if (vTitle || vDate || vLocation || vDifficulty || vPrice) {
            settitleError(vTitle)
            setdateError(vDate)
            setlocationError(vLocation)
            setdifficultyError(vDifficulty)
            setpriceError(vPrice)
            setisError(true)

            if (publish === true) {
                eventTitleRef.current.scrollIntoView()
            }
            return
        }


        if (items) {
            for (var i = 0; i < items.length; i++) {
                const error = validateHarmonogram(items[i], i)

                if (error !== 0) {
                    setHarmonogramError(error)
                    harmonogramRef.current.scrollIntoView()
                    return;
                }
            }
        }


        if (categoryItems && categoryItems.length > 0) {
            for (var i = 0; i < categoryItems.length; i++) {
                const error = validateCategory(categoryItems[i], i)
                if (error !== 0) {
                    setcategoryError(error)
                    categoryRef.current.scrollIntoView()
                    return;
                }
            }
        }


        var poi = {
            date: Date.parse(date),
            title: eventTitle,
            circuit_location: location,
            difficulty: difficulty,
            price: price,
            "details.description": text ? text : ""
        }

        if (items) poi.harmonogram = items
        if (categoryItems) poi.category = categoryItems

        if (publish === true) {
            poi.status = "published"
        }



        const db = getFirestore()
        const eventRef = doc(db, "events_grid", currentEvent)
        const userRef = doc(db, "users", currentUser.data.userId)

        const batch = writeBatch(db)

        var newCurrentEvent = []

        const eventsArr = Object.values(currentUser.data.events)

        eventsArr.map((item) => {
            if (item.id === currentEvent) {
                item.title = poi.title
                item.date = poi.date
                if (publish === true) item.status = "published"
            }

            newCurrentEvent.push(item)

        })

        batch.update(eventRef, poi)
        batch.update(userRef, { "events": newCurrentEvent })

        batch.commit().then((snap) => {
            setColor(poi)
            displayToast("Uložené", "success")
            if (publish === true) window.location.reload(false);

        }).catch((err) => {
            console.error(err)
        })

    }

    function HandlePublish() {
        HandleEdit(true)
    }

    function HandleCancellation() {
        if (window.confirm(`Naozaj chcete zrušiť pretek?`)) {

            const db = getFirestore()
            const eventRef = doc(db, `events_grid/${currentEvent}/`)
            const userRef = doc(db, "users", currentUser.data.userId)

            const batch = writeBatch(db)

            const eventsArr = Object.values(currentUser.data.events)

            var arr = []

            eventsArr.map((item) => {
                if (item.id === currentEvent) {
                    item.status = "cancelled"
                }

                arr.push(item)
            })

            batch.update(userRef, { "events": arr })

            batch.update(eventRef, { "status": "cancelled" })


            batch.commit().then((snap) => {
                window.location.reload(false);

            }).catch((err) => {
                console.error(err)

            })
        }
    }


    return (
        <motion.div layoutScroll className="row">
            <SidePanel id={6}></SidePanel>
            <div className="column content settings">
                <Header></Header>

                {!loading ?
                    <div className="box-content settings">
                        <div className="settings-wrap">
                            <div className="update-bar">
                                {editable && <motion.button
                                    className="button-primary"
                                    onClick={() => HandleEdit()}
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    Uložiť zmeny
                                </motion.button>

                                }

                                <div className="status-indicator">

                                    <div style={{ height: "6px", width: '6px', borderRadius: "50%", backgroundColor: statusColor }}></div>
                                    <div>
                                        {
                                            data.status === 'published' ? "Pretek zverejnený" :
                                                data.status === 'created' ? "Koncept" :
                                                    data.status === 'cancelled' ? "Pretek zrušený" :
                                                        data.status
                                        }</div>

                                </div>

                            </div>

                            {isError && <div div className="error-summary">
                                <div>Zoznam chýb:</div>
                                <ul>
                                    <li>{titleError && titleError}</li>
                                    <li>{dateError && dateError}</li>
                                    <li>{locationError && locationError}</li>
                                    <li>{difficultyError && difficultyError}</li>
                                    <li>{priceError && priceError}</li>
                                </ul>
                            </div>}

                            <div className="input-wrap">
                                <span className="event-id"><b>ID: </b>{currentEvent}</span>
                                <input className="box-shadow form-input heading"
                                    disabled={!editable}
                                    ref={eventTitleRef}
                                    defaultValue={data.title}
                                    placeholder="Názov"
                                    type="text" />
                                <span className='form-validation-error'>{titleError && titleError}</span>
                            </div>

                            <div className="input-wrap date">
                                <span className="input-label">Dátum konania</span>
                                <input className="box-shadow form-input"
                                    disabled={!editable}
                                    ref={dateRef}
                                    defaultValue={data.date}
                                    placeholder="Názov"
                                    type="date" />
                                <span className='form-validation-error'>{dateError && dateError}</span>
                            </div>


                            <div className="input-wrap">
                                <span className="input-label">Miesto konania</span>
                                <input className="box-shadow form-input"
                                    disabled={!editable}
                                    ref={locationRef}
                                    defaultValue={data.circuit_location}
                                    placeholder="Miesto konania"
                                    type="text" />
                                <span className='form-validation-error'>{locationError && locationError}</span>
                            </div>

                            <div className="input-wrap">
                                <span className="input-label">Obtiažnosť</span>
                                <input className="box-shadow form-input"
                                    ref={difficultyRef}
                                    defaultValue={data.difficulty}
                                    placeholder="Obtiažnosť"
                                    disabled={!editable}
                                    type="number" min={0} max={5} />
                                <span className='form-validation-error'>{difficultyError && difficultyError}</span>
                            </div>

                            <div className="input-wrap">
                                <span className="input-label">Cena</span>
                                <input className="box-shadow form-input"
                                    disabled={!editable}
                                    ref={priceRef}
                                    defaultValue={data.price}
                                    placeholder="Cena"
                                    step={"any"}
                                    type="number" />
                                <span className='form-validation-error'>{priceError && priceError}</span>
                            </div>

                            <div ref={harmonogramRef}></div>
                            <AddtimeHarmonogram disabled={!editable} ref={harmonogramRef} error={harmonogramError} items={items} setItems={setItems} />
                            <AddCategory disabled={!editable} ref={categoryRef} error={harmonogramError} items={categoryItems} setItems={setcategoryItems} />

                            <div className="input-wrap">
                                <h1 className="input-label">Krátky opis</h1>
                                {editable ? <ReactQuill
                                    defaultValue={text}
                                    onChange={(value) => { settext(value) }}
                                /> :
                                    <>
                                        {data.details.description ?
                                            <div dangerouslySetInnerHTML={{ __html: data.details.description }}></div>
                                            :
                                            <div>žiadne dáta</div>
                                        }
                                    </>
                                }
                            </div>
                            <div >

                                <div>
                                    <UploadEventImg data={data}></UploadEventImg>
                                </div>

                            </div>
                            {editable && <>
                                <motion.button
                                    className="button-primary publish"
                                    onClick={() => HandlePublish()}
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    Zverejniť pretek
                                </motion.button>

                                <motion.button
                                    className="button-default"
                                    id='save-button'
                                    onClick={DeleteEvent}
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    Zmazať
                                </motion.button>
                            </>

                            }

                            {data.status === "published" && !editable && <motion.button
                                className="button-primary cancel-race"
                                onClick={() => (HandleCancellation())}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Zrušiť pretek
                            </motion.button>}

                        </div>
                    </div> : <Loading></Loading>}

            </div>
        </motion.div>
    )
}
