import React, { useEffect, useState } from 'react'

function Clock() {
    const [clockState, setClockState] = useState(new Date().toLocaleTimeString());

    useEffect(() => {
        setInterval(() => {
            const date = new Date();
            setClockState(date.toLocaleTimeString())
        }, 1000);
    }, [])
    return (
        <span className="main-window-heading">{clockState}</span>
    )
}

function getClockState() {
    return new Date();

}

export { Clock, getClockState };
